import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ChannelService from "~/shared/ChannelService";
import { CHANNEL_IO_PLUGIN_KEY } from "~/shared/env";

/**
 * @author 장건우
 * @description 채널톡 활성화
 */
function useChannelTalk() {
    const { pathname, search, replace } = useLocation();
    const { loginid, shopid, shopname } = useSelector(state => state.user);
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);

    useEffect(() => {
        if (!!loginid && !!shopid && !!shopname) {
            const id = `s${shopid}`;
            ChannelService.boot({
                pluginKey: CHANNEL_IO_PLUGIN_KEY,
                memberId: id,
                profile: {
                    name: shopname,
                    id
                }
            });

            if (searchParams.get("isShowChannelTalk")) {
                // 채널톡 모달을 오픈
                ChannelService.show();
                // 주소창의 url에서 queryParams을 날려버린다.
                replace(pathname);
            }
        }
    }, [loginid, shopid, shopname, pathname, searchParams, replace]);
}

export default useChannelTalk;
