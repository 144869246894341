import { genericError } from "~/packages/utils";
import {
    SUCCESS_FETCH_REVIEWS,
    FAILURE_FETCH_REVIEWS,
    REQUEST_FETCH_REVIEWS,
    REQUEST_FETCH_REVIEWS_COUNT,
    SUCCESS_FETCH_REVIEWS_COUNT,
    FAILURE_FETCH_REVIEWS_COUNT
} from "../actionTypes";

export function fetchReviews(page) {
    return async (dispatch, getState, api) => {
        const {
            user: { shopid: shopId }
        } = getState();

        try {
            if (!shopId) {
                throw genericError("no shopid");
            }

            dispatch({
                type: REQUEST_FETCH_REVIEWS
            });

            if (shopId) {
                let review = await api.fetchReviews({
                    shopId,
                    page
                });

                if (review.length && review.length > 10) {
                    review = review.slice(0, -1);
                }

                dispatch({
                    type: SUCCESS_FETCH_REVIEWS,
                    payload: review
                });
            }
        } catch (err) {
            dispatch({
                type: FAILURE_FETCH_REVIEWS,
                payload: err
            });
        }
    };
}

export function fetchReviewCount() {
    return async (dispatch, getState, api) => {
        const {
            user: { shopid: shopId }
        } = getState();

        try {
            if (!shopId) {
                throw genericError("no shopid");
            }

            dispatch({
                type: REQUEST_FETCH_REVIEWS_COUNT
            });

            const { total } = await api.fetchReviewCount({
                shopId
            });

            dispatch({
                type: SUCCESS_FETCH_REVIEWS_COUNT,
                payload: {
                    pageSize: 10,
                    total
                }
            });
        } catch (err) {
            dispatch({
                type: FAILURE_FETCH_REVIEWS_COUNT,
                payload: err
            });
        }
    };
}
