import {
    FAILURE_GET_SHOP_ASSIGN_INFO,
    REQUEST_GET_SHOP_ASSIGN_INFO,
    SUCCESS_GET_SHOP_ASSIGN_INFO
} from "../actionTypes";

const initialState = {
    isLoading: false,
    isLoaded: false,
    info: {},
    error: null
};

function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case REQUEST_GET_SHOP_ASSIGN_INFO: {
            return {
                ...initialState,
                isLoading: true
            };
        }

        case FAILURE_GET_SHOP_ASSIGN_INFO: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                info: {},
                error: payload
            };
        }

        case SUCCESS_GET_SHOP_ASSIGN_INFO: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                info: payload,
                error: null
            };
        }

        default: {
            return state;
        }
    }
}

export default reducer;
