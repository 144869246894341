import { UPDATE_LOGIN_FIELD } from "../actionTypes";

const initialState = {
    isLoading: false,
    isLoaded: false,
    form: {
        loginid: "",
        password: ""
    },
    error: null
};

function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case UPDATE_LOGIN_FIELD: {
            return {
                ...state,
                form: {
                    ...state.form,
                    [payload.name]: payload.value
                }
            };
        }

        default: {
            return state;
        }
    }
}

export default reducer;
