import { FAILURE_FETCH_NOTIFICATION, REQUEST_FETCH_NOTIFICATION, SUCCESS_FETCH_NOTIFICATION } from "../actionTypes";

export function fetchNotification(shopId, page) {
    return async (dispatch, getState, api) => {
        try {
            dispatch({
                type: REQUEST_FETCH_NOTIFICATION
            });

            const notification = await api.fetchNotification({
                shopId,
                page
            });

            dispatch({
                type: SUCCESS_FETCH_NOTIFICATION,
                payload: notification
            });
        } catch (err) {
            dispatch({
                type: FAILURE_FETCH_NOTIFICATION,
                payload: err
            });
        }
    };
}
