import {
    FAILURE_GET_CASH_CONVERT_COUNT,
    FAILURE_GET_CASH_CONVERT_LIST,
    FAILURE_GET_CASH_INFO,
    FAILURE_GET_SHOP_CASH_INFO,
    REQUEST_GET_CASH_CONVERT_COUNT,
    REQUEST_GET_CASH_CONVERT_LIST,
    REQUEST_GET_CASH_INFO,
    REQUEST_GET_SHOP_CASH_INFO,
    SUCCESS_GET_CASH_CONVERT_COUNT,
    SUCCESS_GET_CASH_CONVERT_LIST,
    SUCCESS_GET_CASH_INFO,
    SUCCESS_GET_SHOP_CASH_INFO
} from "../actionTypes";

const initialState = {
    isLoading: false,
    isLoaded: false,
    shopCash: {},
    autoPay: {},
    convertedPayment: [],
    count: 0,
    error: null
};

function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case REQUEST_GET_CASH_INFO: {
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                shopCash: {},
                error: null
            };
        }

        case FAILURE_GET_CASH_INFO: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                shopCash: {},
                error: payload
            };
        }

        case SUCCESS_GET_CASH_INFO: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                shopCash: payload,
                error: null
            };
        }

        case REQUEST_GET_SHOP_CASH_INFO: {
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                autoPay: {},
                error: null
            };
        }

        case FAILURE_GET_SHOP_CASH_INFO: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                autoPay: {},
                error: payload
            };
        }

        case SUCCESS_GET_SHOP_CASH_INFO: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                autoPay: payload,
                error: null
            };
        }

        case REQUEST_GET_CASH_CONVERT_LIST: {
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                convertedPayment: [],
                error: null
            };
        }

        case FAILURE_GET_CASH_CONVERT_LIST: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                convertedPayment: [],
                error: payload
            };
        }

        case SUCCESS_GET_CASH_CONVERT_LIST: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                convertedPayment: payload,
                error: null
            };
        }

        case REQUEST_GET_CASH_CONVERT_COUNT: {
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                count: 0,
                error: null
            };
        }

        case FAILURE_GET_CASH_CONVERT_COUNT: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                count: 0,
                error: payload
            };
        }

        case SUCCESS_GET_CASH_CONVERT_COUNT: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                count: payload,
                error: null
            };
        }

        default: {
            return state;
        }
    }
}

export default reducer;
