import React, { memo } from "react";
import PropTypes from "prop-types";
import { Pane } from "evergreen-ui";

function Flex({ children, ...props }) {
    return (
        <Pane display={"flex"} {...props}>
            {children}
        </Pane>
    );
}

Flex.propTypes = {
    children: PropTypes.node.isRequired
};

export default memo(Flex);
