import { REDIRECT_QUERY_PARAM_KEY, genericError } from "~/packages/utils";
import { HOME, LOGIN } from "~/routes";
import Cookie from "~/shared/Cookies";
import { LEGACY_SHOPADMIN } from "~/shared/env";
import { invalidAlert } from "~/shared/toasts";
import {
    FAILURE_CHECK_ADMIN_USER,
    FAILURE_LOGIN,
    FAILURE_LOGOUT,
    FAILURE_VERIFY_AUTH,
    REQUEST_CHECK_ADMIN_USER,
    REQUEST_LOGIN,
    REQUEST_LOGOUT,
    REQUEST_VERIFY_AUTH,
    SET_SHOP_BY_ADMIN,
    SUCCESS_CHECK_ADMIN_USER,
    SUCCESS_LOGIN,
    SUCCESS_LOGOUT,
    SUCCESS_VERIFY_AUTH
} from "../actionTypes";
import { fetchRepairShops } from "./assets";

export function checkAdminUser() {
    return async (dispatch, getState, api) => {
        dispatch({
            type: REQUEST_CHECK_ADMIN_USER
        });

        try {
            const { blocked, type, loginid } = await api.checkAdminUser();

            if (blocked === "1") {
                // NOTE admin, but blocked
                invalidAlert("account blocked!");
                Cookie.remove();
                location?.replace(LOGIN);
            }

            if (!loginid) {
                // NOTE no error
                throw genericError("no admin");
            }

            dispatch({
                type: SUCCESS_CHECK_ADMIN_USER,
                payload: {
                    loginid,
                    type
                }
            });

            if (type === "admin") {
                dispatch(fetchRepairShops());
            }
        } catch (err) {
            dispatch({
                type: FAILURE_CHECK_ADMIN_USER,
                payload: err
            });
        }
    };
}

export function verifyAuth() {
    return async (dispatch, getState, api) => {
        dispatch({
            type: REQUEST_VERIFY_AUTH
        });

        try {
            const {
                account_type: accountType,
                account_type2: accountType2,
                business_type: businessType,
                is_admin: isAdmin,
                shopname,
                shopid,
                loginid,
                deleted
            } = await api.verifyAuth();

            if (deleted === "1") {
                throw genericError("invalid shop user", {
                    isInvalid: true
                });
            }

            if (isAdmin) {
                dispatch(checkAdminUser());
            }

            dispatch({
                type: SUCCESS_VERIFY_AUTH,
                payload: {
                    accountType,
                    accountType2,
                    businessType,
                    shopid,
                    shopname,
                    loginid,
                    type: accountType
                }
            });
        } catch (err) {
            dispatch({
                type: FAILURE_VERIFY_AUTH,
                payload: err
            });

            if (err.isInvalid) {
                Cookie.remove();
            }
        }
    };
}

// query params 사용하면 다시 사용할 파라메터 받는 함수
// export function login(path) {
export function login() {
    return async (dispatch, getState, api) => {
        const {
            formLogin: {
                form: { loginid, password }
            }
        } = getState();

        dispatch({
            type: REQUEST_LOGIN
        });

        try {
            const { type, grade } = await api.login({
                loginid,
                password
            });

            const url = new URL(location.href);
            const redirectParam = url.searchParams.get(REDIRECT_QUERY_PARAM_KEY);

            /**
             * @desc 신파트너스웹에서 테크샵 계정으로 로그인시 테크샵 파트너스웹으로 replace
             */
            if (type === "techshop" && !redirectParam) {
                window?.location.replace(`${LEGACY_SHOPADMIN}/techshop/reserve/condition`);
                return;
            }

            await dispatch({
                type: SUCCESS_LOGIN,
                payload: { type, grade }
            });

            if (redirectParam) {
                location.replace(redirectParam);
                return;
            }

            const preScreenUrl = window.sessionStorage.getItem("preScreen");

            if (preScreenUrl) {
                location?.replace(preScreenUrl);
                window.sessionStorage.removeItem("preScreen");
            } else {
                location?.replace(HOME);
            }
        } catch (err) {
            const { status } = err.response ?? {};

            if (status === 404) {
                invalidAlert("아이디 혹은 암호를 확인 부탁드립니다.");
            }

            if (status >= 500) {
                invalidAlert("서버 접근이 어려운 상황입니다.");
            }

            dispatch({
                type: FAILURE_LOGIN,
                payload: err
            });
        }
    };
}

export function logout() {
    return async (dispatch, getState, api) => {
        dispatch({
            type: REQUEST_LOGOUT
        });

        try {
            await api.logout();

            dispatch({
                type: SUCCESS_LOGOUT
            });

            location?.replace(LOGIN);
        } catch (err) {
            dispatch({
                type: FAILURE_LOGOUT,
                payload: err
            });
        }
    };
}

export function setShopByAdmin(shop = {}) {
    return {
        type: SET_SHOP_BY_ADMIN,
        payload: shop
    };
}
