import { createContext, useContext } from "react";
import amplitude from "amplitude-js";

class Amplitude {
    constructor(name) {
        this.instance = null;
        this.projectName = name;
    }

    init(apiKey, userId) {
        if (apiKey) {
            this.instance = amplitude.getInstance(this.projectName);
            this.instance.init(apiKey, userId);
        }
    }

    logEvent(...args) {
        if (this.instance) {
            this.instance.logEvent(...args);
        }
    }
}

export const AmplitudeContext = createContext();

export function useAmplitude() {
    return useContext(AmplitudeContext);
}

export default Amplitude;
