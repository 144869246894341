import qs from "querystring";
import createRequest from "~/packages/request";
import { bindParams, clean } from "~/packages/utils";
import { LOGIN } from "~/routes";
import { API_ENDPOINT, API_PARTNERS_ENDPOINT, API_PARTNERS_VERSION, API_VERSION, IS_DEV } from "~/shared/env";
import { timeoutOccurred } from "~/shared/toasts";
import {
    CHECK_ADMIN_USER,
    CREATE_SESSION,
    DELETE_REPAIR_CASE,
    DELETE_SESSION,
    FETCH_ADVERTISEMENT,
    FETCH_ANNOUNCEMENT,
    FETCH_ANNOUNCEMENTS,
    FETCH_BALANCE,
    FETCH_CAR_BRAND_TYPE_LIST,
    FETCH_CATEGORY_05,
    FETCH_CATEGORY_06,
    FETCH_CATEGORY_07,
    FETCH_CHAT_MESSAGES,
    FETCH_ESTIMATION,
    FETCH_ESTIMATIONS,
    FETCH_ESTIMATIONS_COUNT,
    FETCH_INSTANT_ADS_INFO,
    FETCH_NOTIFICATION,
    FETCH_REPAIR_CASE,
    FETCH_REPAIR_CASES,
    FETCH_REPAIR_PARTS_LIST,
    FETCH_REVIEW,
    FETCH_REVIEW_COUNT,
    FETCH_SHOPS,
    FETCH_SHOP_INFO,
    FETCH_SHOP_REPAIR_LIST,
    FETCH_TERMS,
    GET_ANNOUNCEMENTS_COUNT,
    GET_REPAIR_CASE_COUNT,
    GET_UNREAD_NOTI_COUNT,
    NOTI_ALL_READ,
    READ_NOTIFICATION,
    SUBMIT_ADVERTISEMENT,
    SUBMIT_CHAT_IMAGE,
    SUBMIT_CHAT_MESSAGE,
    SUBMIT_ONELINE_AD_TEXT,
    SUBMIT_REPAIR_CASE,
    SUBSCRIBE_INSTANT_ADS,
    UPDATE_ESTIMATE,
    UPDATE_REPAIR_CASE,
    UPLOAD_FILES,
    VERIFY_SESSION,
    VERIFY_TERMS_STATE
} from "./constants";
import { makeSafeNumber } from "~/packages/utils/applyCurrency";
const AUTH_CHECK_STATUS_LIST = [401, 403];

const request = createRequest({
    options: {
        baseURL: clean([!IS_DEV && API_ENDPOINT, "/", API_VERSION]).join(""),
        headers: {
            "x-cardoc-service": "shopadmin"
        }
    },
    onSuccess: ({ data }) => data,
    onTimeout: () => timeoutOccurred(),
    onError: err => {
        if (err?.response?.status && AUTH_CHECK_STATUS_LIST.includes(err?.response?.status)) {
            const { config } = err.response;
            const excludedApiUrls = ["/phoneAuth/authCode"];
            if (excludedApiUrls.includes(config.url)) {
                return;
            }

            const isLoginPage = location?.pathname === LOGIN;

            if (!isLoginPage) {
                window.sessionStorage.setItem("preScreen", window.location.pathname);
                location?.replace(LOGIN);
            }
        }
    }
});

const requestPlatform = createRequest({
    options: {
        baseURL: clean([API_PARTNERS_ENDPOINT, "/", API_PARTNERS_VERSION]).join(""),
        headers: {
            "X-Cardoc-Service": "partners-web"
        }
    },
    onSuccess: ({ data }) => data,
    onTimeout: () => timeoutOccurred(),
    onError: err => {
        const { status } = err.response;

        if (AUTH_CHECK_STATUS_LIST.includes(status)) {
            const isLoginPage = location?.pathname === LOGIN;

            if (!isLoginPage) {
                window.sessionStorage.setItem("preScreen", window.location.pathname);
                location?.replace(LOGIN);
            }
        }
    }
});

function generateFormData(data, formData = new FormData()) {
    Object.keys(data).forEach(key => {
        const val = [data[key]].flat();

        formData.append(key, ...val);
    });

    return formData;
}

/**
 * Check whether admin user
 * @return {Promise}
 */
export function checkAdminUser() {
    return request.get(CHECK_ADMIN_USER);
}

/**
 * Check whether shop user session
 * @return {Promise}
 */
export function verifyAuth() {
    return request.get(VERIFY_SESSION);
}

/**
 * Check whether shop user session
 * @return {Promise}
 */
export function fetchShopInfo() {
    return request.get(FETCH_SHOP_INFO);
}

/**
 * Create user session
 * @param  {String}  loginid
 * @param  {Number}  password
 * @return {Promise}
 */
export function login({ loginid, password } = {}) {
    return request.post(CREATE_SESSION, {
        loginid,
        password
    });
}

/**
 * Delete user session
 * @return {Promise}
 */
export function logout() {
    return request.delete(DELETE_SESSION);
}

/**
 * Check whether shop admin user passed terms agreement
 * @return {Promise}
 */
export function verifyTermsState() {
    return request.get(VERIFY_TERMS_STATE);
}

/**
 * Fetch terms list
 * @return {Promise}
 */
export function fetchTerms() {
    return request.get(FETCH_TERMS);
}

/**
 * Fetch estimations
 * @param  {String}  shopId
 * @param  {String}  statusCode
 * @param  {Object}  filters
 * @param  {Number}  page
 * @return {Promise}
 */
export function fetchEstimations({ shopId, statusCode, filters, page = 1 }) {
    let url = bindParams(FETCH_ESTIMATIONS, {
        shopId,
        statusCode,
        page
    });

    url = `${url}?${qs.stringify(filters)}`;

    return request.get(url);
}

/**
 * Fetch estimation
 * @param  {String}  estId
 * @return {Promise}
 */
export function fetchEstimation(estId) {
    return request.get(
        bindParams(FETCH_ESTIMATION, {
            estId
        })
    );
}

/**
 * Fetch estimations count by shop
 * @param  {String}  shopId
 * @param  {String}  statusCode
 * @param  {Object}  filters
 * @param  {Number}  page
 * @return {Promise}
 */
export function fetchEstimationsCount({ shopId, statusCode, filters, page = 1 }) {
    let url = bindParams(FETCH_ESTIMATIONS_COUNT, {
        shopId,
        statusCode,
        page
    });

    url = `${url}?${qs.stringify(filters)}`;

    return request.get(url);
}

/**
 * Get unread notification count
 * @param  {String}  shopId
 * @return {Promise}
 */

export function getUnreadNotiCount(shopId) {
    return requestPlatform.get(bindParams(GET_UNREAD_NOTI_COUNT, { shopId }));
}

/**
 * Get balance data
 * @param  {String}  shopId
 * @return {Promise}
 */

export function getBalance(shopId) {
    return request.get(bindParams(FETCH_BALANCE, { shopId }));
}

/**
 * Fetch announcement list
 * @param {Number} page
 * @return {Promise}
 */

export function fetchAnnouncements({ page = 1 }) {
    return request.get(
        bindParams(FETCH_ANNOUNCEMENTS, {
            page
        })
    );
}

/**
 * Fetch announcement content
 * @param {Number} annId
 * @return {Promise}
 */

export function fetchAnnouncement({ annId }) {
    return request.get(
        bindParams(FETCH_ANNOUNCEMENT, {
            annId
        })
    );
}

/**
 * Get announcement totalcount
 * @param {Number} page
 * @return {Promise}
 */

export function getAnnouncementsCount() {
    return request.get(bindParams(GET_ANNOUNCEMENTS_COUNT));
}

/**
 * Fetch notification
 * @param  {String}  shopId
 * @param {Number} page
 * @param {Number} perPage
 * @return {Promise}
 */

export function fetchNotification({ shopId, page = 1, perPage = 20 }) {
    const params = { page, perPage };
    return requestPlatform.get(bindParams(FETCH_NOTIFICATION, { shopId }), {
        params
    });
}

/**
 * Request notification all read
 * @param  {String}  shopId
 * @return {Promise}
 */

export function requestNotiAllRead(shopId) {
    return requestPlatform.patch(bindParams(NOTI_ALL_READ, { shopId }));
}

/**
 * Request notification all read
 * @param {Number} notiId
 * @return {Promise}
 */

export function requestNotiRead(notiId) {
    return requestPlatform.patch(bindParams(READ_NOTIFICATION, { notiId }));
}

/**
 * Fetch repair case list
 * @param  {String}  shopId
 * @param {Number} page
 * @return {Promise}
 */

export function fetchRepairCases({ shopId, page = 1 }) {
    return request.get(bindParams(FETCH_REPAIR_CASES, { shopId, page }));
}

/**
 * Fetch repair case connected estimation list
 * @param  {String}  shopId
 * @return {Promise}
 */

export function fetchShopRepairList({ shopId, estId = 0 }) {
    return request.get(bindParams(FETCH_SHOP_REPAIR_LIST, { shopId, estId }));
}

/**
 * Fetch repair parts list
 * @return {Promise}
 */

export function fetchRepairPartList() {
    return request.get(FETCH_REPAIR_PARTS_LIST);
}

/**
 * Fetch repair case car brand & type list
 * @return {Promise}
 */

export function fetchCarBrandTypeList() {
    return request.get(FETCH_CAR_BRAND_TYPE_LIST);
}

/**
 * Fetch repair case detail
 * @param  {Number}  caseId
 * @return {Promise}
 */

export function fetchRepairCase({ caseId }) {
    return request.get(bindParams(FETCH_REPAIR_CASE, { caseId }));
}

/**
 * Submit repair case (create & edit)
 * @param  {String}  shopId
 * @param {Number} page
 * @return {Promise}
 */

export function postRepairCase(repairCaseData) {
    return request.post(SUBMIT_REPAIR_CASE, {
        ...repairCaseData
    });
}

/**
 * Delete repair case
 * @param  {Number}  caseId
 * @return {Promise}
 */
export function deleteRepairCase(caseId) {
    return request.delete(bindParams(DELETE_REPAIR_CASE, { caseId }));
}

/**
 * edit repair case
 * @param  {Number}  caseId
 * @return {Promise}
 */
export function updateRepairCase(caseId, repairCaseData) {
    return request.put(bindParams(UPDATE_REPAIR_CASE, { caseId }), {
        ...repairCaseData
    });
}

/**
 * Fetch repair case total count
 * @param  {String}  shopId
 * @param {Number} page
 * @return {Promise}
 */

export function getRepairCaseCount({ shopId }) {
    return request.get(bindParams(GET_REPAIR_CASE_COUNT, { shopId }));
}

/**
 * Subscribe instant(one line) ads
 * @param  {String}  shopId
 * @param  {String}  value
 * @return {Promise}
 */
export function subscribeInstantAds({ shopId, value }) {
    return request.post(SUBSCRIBE_INSTANT_ADS, {
        shopId,
        value
    });
}

/**
 * Fetch category 05
 * @return {Promise}
 */
export function fetchCategory05() {
    return request.get(FETCH_CATEGORY_05);
}

/**
 * Fetch category 06
 * @return {Promise}
 */
export function fetchCategory06() {
    return request.get(FETCH_CATEGORY_06);
}

/**
 * Fetch category 07
 * @return {Promise}
 */
export function fetchCategory07() {
    return request.get(FETCH_CATEGORY_07);
}

/**
 * Update estimate
 * @return {Promise}
 */
export function updateEstimate({ estId, data }) {
    return request.put(
        bindParams(UPDATE_ESTIMATE, {
            estId
        }),
        data
    );
}

/**
 * Cancel booking
 * @param  {String}  estId
 * @param  {Object}  data
 * @return {Promise}
 */
export function cancelBooking(...args) {
    return updateEstimate(...args);
}

/**
 * Update booking
 * @param  {String}  estId
 * @param  {Object}  data
 * @return {Promise}
 */
export function updateBooking(...args) {
    return updateEstimate(...args);
}

/**
 * Confirm booking
 * @param  {String}  estId
 * @param  {Object}  data
 * @return {Promise}
 */
export function confirmBooking(...args) {
    return updateEstimate(...args);
}

/**
 * Fetch chat messages
 * @param  {String}  estId
 * @param  {String}  reqId
 * @param  {String?} latestChatId
 * @return {Promise}
 */
export function fetchChatMessages({ estId, reqId, latestChatId = "0" }) {
    return request.get(
        bindParams(FETCH_CHAT_MESSAGES, {
            estId,
            reqId,
            latestChatId
        })
    );
}

/**
 * Fetch repair shops
 * @return {Promise}
 */
export function fetchRepairShops() {
    return request.get(
        bindParams(FETCH_SHOPS, {
            service: "shopmap_repair"
        })
    );
}

/**
 * Submit chat message
 * @param  {String}  estId
 * @param  {String}  msg
 * @return {Promise}
 */
export function submitChatMessage({ estId, msg }) {
    return request.post(SUBMIT_CHAT_MESSAGE, {
        msg_type: "text",
        isshop: "Y",
        estid: estId,
        reqid: 0,
        msg
    });
}

/**
 * Submit chat image
 * @param  {String}  estId
 * @param  {String}  image id
 * @return {Promise}
 */
export function submitChatImage({ estId, image }) {
    return request.post(SUBMIT_CHAT_IMAGE, {
        msg_type: "image",
        isshop: "Y",
        estid: estId,
        reqid: 0,
        msg: `@@${image}`
    });
}

/**
 * Upload file
 * @param  {String?} [uploadtype=chat]
 * @param  {Object}  file
 * @param  {String}  shopid
 * @return {Promise}
 */
export function uploadFile({ uploadtype = "chat", file, shopid }) {
    const formData = generateFormData({
        files: file.name,
        "files[]": [file, file.name],
        shopid,
        uploadtype
    });

    return request.post(UPLOAD_FILES, formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
}

/**
 * fetch notice
 * @param  {String?} [uploadtype=chat]
 * @param  {String}  shopId
 * @return {Promise}
 */
export function fetchReviews({ shopId, page = 1 }) {
    const url = bindParams(FETCH_REVIEW, {
        shopId,
        page
    });

    return request.get(url);
}

/**
 * Fetch estimations count by shop
 * @param  {String}  shopId
 * @return {Promise}
 */
export function fetchReviewCount({ shopId }) {
    return request.get(
        bindParams(FETCH_REVIEW_COUNT, {
            shopId
        })
    );
}

/**
 * Fetch ads list get
 * @param  {String}  shopId
 * @return {Promise}
 */
export function fetchInstantAdsInfo(shopId) {
    return request.get(bindParams(FETCH_INSTANT_ADS_INFO, { shopId }));
}

/**
 * 비밀번호 재설정 1
 * @param {string} phoneNumber
 */
export function putResetPassword(phoneNumber) {
    // eslint-disable-next-line no-return-await
    return request.put("/account/reset_password", {
        identifier: phoneNumber,
        type: "shop"
    });
}

/**
 * 비밀번호 재설정 2-1
 * @param {string} phoneNumber
 * @param {string} authCode
 */
export function postAuthCode(phoneNumber, authCode) {
    // eslint-disable-next-line no-return-await
    return request.post("/phoneAuth/authCode", {
        phonenumber: phoneNumber,
        authcode: authCode
    });
}

/**
 * 비밀번호 재설정 2-2
 * @param {string} phoneNumber
 */
export function getShop(phoneNumber) {
    // eslint-disable-next-line no-return-await
    return request.get(`/shop/mobilenum/${phoneNumber}`);
}

/**
 * 비밀번호 재설정 3
 * @param {string} loginId
 * @param {string} newPassword
 */
export function putRenewPassword(loginId, newPassword) {
    // eslint-disable-next-line no-return-await
    return request.put(`/account/${loginId}/renew_password`, {
        new_password: newPassword
    });
}

/**
 * 내 정보
 * @param {string} shopId
 */
export function getMyShop(shopId) {
    return request.get(`/shop/${shopId}`);
}

/**
 * 내 정보 수정
 * @param {string} shopInfo
 */
export function updateMyShop(shopInfo) {
    return request.put(`/shop/${shopInfo.shopid}`, {
        ...shopInfo
    });
}

/**
 * 비밀번호 업데이트
 * @param {string} shopId
 * @param {object} data
 *  shopid
 *  loginid
 *  oldpassword
 *  password
 */
export function updatePassword(shopId, data) {
    return request.put(`/shop/${shopId}`, {
        updatetype: "changepassword",
        ...data
    });
}

/**
 * 은행 목록
 * @return {Promise}
 */
export function getBanks() {
    return request.get(`/banks`);
}

/**
 * 협력업체 목록
 * @return {Promise}
 */
export function getShopPartners() {
    return request.get(`/shopPartners`);
}

/**
 * 서비스 지역 목록
 * @return {Promise}
 */
export function getServiceArea() {
    return request.get("/serviceArea/for_address/search/all");
}

/**
 * 지역 광고 신청 가능 지역 리스트 가져오기
 * @param {string} shopId
 */
export function fetchLocalAdvertisementList({ shopId }) {
    return request.get(
        bindParams(FETCH_ADVERTISEMENT, {
            shopId
        })
    );
}

/**
 * 지역 광고 신청 요청
 * @param {string} shopId
 * @param {Array} groupAreaIdList
 */

export function putLocalAdvertisement(shopId, groupAreaIdList) {
    return request.put(
        bindParams(SUBMIT_ADVERTISEMENT, {
            shopId
        }),
        {
            action: "register_area",
            groupareaids: groupAreaIdList
        }
    );
}

/**
 * 견적서 ID로 연결된 알림 읽음 처리
 * @param {string} estId
 */
export function patchAppointmentsNotificationRead(estId) {
    return requestPlatform.patch(`/bms/repair/appointments/${estId}/notification/read`);
}

/**
 * 지역 설정 - info
 * @return {Promise}
 */
export function getShopAssignInfo(shopId) {
    return request.get(`/shopAssignInfo/${shopId}`);
}

/**
 * 지역 설정 - info
 * @return {Promise}
 */
export function putShopAssignInfo(shopId, data) {
    return request.put(`/shopAssignInfo/${shopId}`, {
        updatetype: "update_setting",
        ...data
    });
}

/**
 * 지역 설정 - regional info
 * @return {Promise}
 */
export function getShopAssignInfoSettings(shopId) {
    return request.get(`/shopAssignInfo/setting/${shopId}`);
}

/**
 * 지역 설정 - fittedReservation
 * @return {Promise}
 */
export function getShopFittedReservation(shopId) {
    return request.get(`/fittedReservation/gettype/surcharge/sid/${shopId}`);
}

/**
 * 지역 설정 - fittedReservation
 * @return {Promise}
 */
export function getRepairConfigs() {
    return request.get(`/repairConfigs`);
}

/**
 * 한줄 광고 문구 추가 요청
 * @param  {String}  shopId
 * @param  {String}  adText
 * @return {Promise}
 */
export function postOneLineAdText(shopId, adText) {
    return request.post(SUBMIT_ONELINE_AD_TEXT, {
        shopid: shopId,
        value: adText
    });
}

/**
 * 사용자의 캐시 정보 가져오는 api, 실제 갖고 있는 값
 * @return {Promise}
 */
export function getCashBalance(shopId) {
    return request.get(`/cardocCashBalances/${shopId}`);
}

/**
 * 사용자의 자동 결제 관련된 정보 가져오기
 * @return {Promise}
 */
export function getAutoPayment(shopId) {
    return request.get(`/autopay/shop/shopid/${shopId}`);
}

/**
 * 앱 결제금액 카닥캐시 전환가능한 결제건 가져오기
 * @return {Promise}
 */
export function getCashConvertedPaymentList(shopId, page = 1, pageSize = 10, isExcludeConverted) {
    let url = `/pay/shop/${shopId}/page/${page}/pagesize/${pageSize}`;
    if (isExcludeConverted) url += "/exclude_converted_payments_yn/Y";
    return request.get(url);
}

/**
 * 앱 결제금액 카닥캐시 전환가능한 결제건 pageSize 가져오기
 * @return {Promise}
 */
export function getCashConvertedPaymentCount(shopId, isExcludeConverted) {
    let url = `/pay/shop/${shopId}/countYn/Y`;
    if (isExcludeConverted) url += "/exclude_converted_payments_yn/Y";
    return request.get(url);
}

/**
 * 자동충전 카드 등록하기
 * @return {Promise}
 */
export function postRegisterAutoPay({ shopId, authfield1, authfield2, cardexpm, cardexpy, cardnumber, price }) {
    return request.post("/autopay", {
        authentification: "00",
        payday: "25",
        buyername: "",
        shopid: shopId,
        authfield1,
        authfield2,
        cardexpm: `00${cardexpm}`.slice(-2),
        cardexpy: cardexpy.slice(-2),
        cardnumber,
        price: makeSafeNumber(price.toString().replace(/[^0-9]/g, "")).toString()
    });
}

/**
 * 캐시 소진 내역 조회 "기간별 내역"
 * @param {string} shopId
 * @param {propertyType: 'monthly' | 'daily'} aggregation
 * @param {string} startDate
 * @param {string} endDate
 * @param {Number} page
 * @param {Number} pageSize
 * @return {Promise}
 */
export function getCardocCashPeriodSummaryList(shopId, aggregation = "daily", startDate, endDate, page, pageSize) {
    return request.get(
        `/cardocCashTx/periodicSummary/shop/${shopId}/aggregation/${aggregation}/start/${startDate}/end/${endDate}/page/${page}/pageSize/${pageSize}`
    );
}

/**
 * 캐시 소진 내역 조회 "기간별 내역 카운트"
 * @param {string} shopId
 * @param {propertyType: 'monthly' | 'daily'} aggregation
 * @param {string} startDate
 * @param {string} endDate
 * @return {Promise}
 */
export function getCardocCashPeriodSummaryCount(shopId, aggregation, startDate, endDate) {
    return request.get(
        `/cardocCashTx/periodicSummaryCount/shop/${shopId}/aggregation/${aggregation}/start/${startDate}/end/${endDate}/pageSize/10`
    );
}

/**
 * 캐시 소진 내역 "충전 내역"
 * @param {string} shopId
 * @param {string} startDate
 * @param {string} endDate
 * @param {Number} page
 * @param {Number} pageSize
 * @return {Promise}
 */
export function getCardocCashChargeHistoryList(shopId, startDate, endDate, page, pageSize, isPaidChargeOnly = false) {
    let url = `/cardocCashTx/chargeHistory/shop/${shopId}/start/${startDate}/end/${endDate}/page/${page}/pageSize/${pageSize}`;
    if (isPaidChargeOnly) {
        url += "/category/paid";
    }
    return request.get(url);
}

/**
 * 캐시 소진 내역 "충전 내역 카운트"
 * @param {string} shopId
 * @param {string} startDate
 * @param {string} endDate
 * @param {Number} pageSize
 * @return {Promise}
 */
export function getCardocCashChargeHistoryCount(shopId, startDate, endDate, pageSize, isPaidChargeOnly = false) {
    let url = `/cardocCashTx/chargeHistoryCount/shop/${shopId}/start/${startDate}/end/${endDate}/pageSize/${pageSize}`;
    if (isPaidChargeOnly) {
        url += "/category/paid";
    }
    return request.get(url);
}

/**
 * 캐시 소진 내역 "거래 내역"
 * @param {string} shopId
 * @param {string} startDate
 * @param {string} endDate
 * @return {Promise}
 */
export function getCardocCashUseHistoryList(shopId, startDate, endDate) {
    return request.get(`/cardocCashTx/useHistory/shop/${shopId}/start/${startDate}/end/${endDate}`);
}

/**
 * 캐시 소진 내역 "거래 내역 -> 월이용료 상세"
 * @param {string} shopId
 * @param {Number} page
 * @param {Number} pageSize
 * @param {propertyType: 'shopListAdHistory' | 'monthlyFareHistory'} detailName
 * @return {Promise}
 */
export function getCardocCashUseHistoryMonthPaymentList(shopId, page, pageSize, detailName) {
    return request.get(`/cardocCashTx/${detailName}/shop/${shopId}/page/${page}/pageSize/${pageSize}`);
}

/**
 * 캐시 소진 내역 "거래 내역 -> 월이용료 상세 카운트"
 * @param {string} shopId
 * @param {Number} pageSize
 * @param {propertyType: 'shopListAdHistory' | 'monthlyFareHistory'} detailName
 * @return {Promise}
 */
export function getCardocCashUseHistoryMonthPaymentCount(shopId, pageSize, detailName) {
    return request.get(`/cardocCashTx/${detailName}Count/shop/${shopId}/pageSize/${pageSize}`);
}

/**
 * 캐시 소진 내역 "견적수신 내역"
 * @param {string} shopId
 * @param {string} startDate
 * @param {string} endDate
 * @param {Number} page
 * @param {Number} pageSize
 * @return {Promise}
 */
export function getCardocCashCashBackList(shopId, startDate, endDate, page, pageSize) {
    return request.get(
        `/cardocCashTx/cashbacks/shop/${shopId}/start/${startDate}/end/${endDate}/page/${page}/pageSize/${pageSize}`
    );
}

/**
 * 캐시 소진 내역 "견적수신 내역 카운트"
 * @param {string} shopId
 * @param {string} startDate
 * @param {string} endDate
 * @param {Number} pageSize
 * @return {Promise}
 */
export function getCardocCashCashBackCount(shopId, startDate, endDate, pageSize) {
    return request.get(
        `/cardocCashTx/cashbackCount/shop/${shopId}/start/${startDate}/end/${endDate}/pageSize/${pageSize}`
    );
}

/**
 * 캐시 소진 내역 "앱결제 관리"
 * @param {string} shopId
 * @param {Number} pageSize
 * @param {Number} page
 * @return {Promise}
 */
export function getCardocCashAppPayment(shopId, page, pageSize) {
    return request.get(`/pay/shop/${shopId}/page/${page}/pagesize/${pageSize}`);
}

/**
 * 캐시 소진 내역 "앱결제 관리"
 * @param {string} shopId
 * @return {Promise}
 */
export function getCardocCashAppPaymentCount(shopId) {
    return request.get(`/pay/shop/${shopId}/countYn/Y`);
}

/**
 * 현장 결제 한거 카닥캐시 전환
 * @param {string} reqid
 * @param {string} amount
 * @param {string} pid
 * @param {string} shopId
 * @return {Promise}
 */
export function putCardocCashChangeToMoney({ reqid, amount, pid, shopId }) {
    return request.put(`/pay/${reqid}`, {
        pid,
        shopid: shopId,
        proc_type: "paid_to_shop",
        proc_memo: null,
        proc_cash_charge: amount,
        proc_pay_method: "payment_sa",
        updatetype: "postproc"
    });
}

/**
 * 신용카드 충전하기
 * @param {string} shopId
 * @param {string} amount: “100000”
 * @param {string} cardexpm: “06"
 * @param {string} cardexpy: “25”
 * @param {string} cardnumber: “5365101250624354"
 * @param {string} cardquota: “1” => 일시불
 * @param {string} goodsname: “카닥캐시 일반충전”
 * @param {string} shopid: “1”
 * @return {Promise}
 */
export function postRechargeByCredit({ amount, cardexpm, cardexpy, cardnumber, cardquota, shopId }) {
    return request.post(`/pay`, {
        amount,
        cardexpm: `00${cardexpm}`.slice(-2),
        cardexpy: cardexpy.slice(-2),
        cardnumber,
        cardquota,
        goodsname: `카닥캐쉬 일반충전`,
        shopid: shopId
    });
}

/**
 * 자동 충전 해제
 * @param {string} shopId
 * @return {Promise}
 */
export function deleteRecahrgeCard(shopId) {
    return request.delete(`/autopay/shop/shopid/${shopId}`);
}

/**
 * 자동 충전 재시도
 * @param {string} shopId
 * @return {Promise}
 */
export function updateRecahrgeCard(shopId) {
    return request.put(`/autopay/shopid/${shopId}`);
}

/** @desc estimation often text api */

/**
 *
 * @param {string} shopId
 * @returns {Promise<{id:number; content:string; }[]>}
 */
export function getEstimationOftenTexts(shopId) {
    return requestPlatform.get(`/bms/repair/desc-boilerplates/shopid/${shopId}`);
}

/**
 *
 * @param {number} descId
 * @param {number} shopId
 * @returns {Promise<{id:number; content:string; }>}
 */
export function getEstimationOftenTextDetail(descId, shopId) {
    return requestPlatform.get(`/bms/repair/desc-boilerplates/${descId}/shopid/${shopId}`);
}

/**
 *
 * @param {number} descId
 * @param {number} shopId
 * @param {string} content
 * @returns {Promise<{id:number; content:string; }>}
 */
export function updateEstimationOftenTextDetail(descId, shopId, content) {
    return requestPlatform.patch(`/bms/repair/desc-boilerplates/${descId}/shopid/${shopId}`, { content });
}

/**
 *
 * @param {number} descId
 * @param {number} shopId
 * @returns {Promise<{id: number; isDeleted: boolean;}>}
 */
export function deleteEstimationOftenTextDetail(descId, shopId) {
    return requestPlatform.delete(`/bms/repair/desc-boilerplates/${descId}/shopid/${shopId}`);
}

/**
 *
 * @param {number} shopId
 * @param {string} content
 * @returns {Promise<{id:number; content:string; }>}
 */
export function createEstimationOftenText(shopId, content) {
    return requestPlatform.post(`/bms/repair/desc-boilerplates/shopid/${shopId}`, { content });
}
