import { priceItem } from "~/core";
import { assocPath } from "ramda";
import { parsePath } from "use-redux-form/es";
import Swal from "sweetalert2";
import { getShortId } from "~/packages/utils";
import {
    APPEND_PRICE_ITEM,
    FAILURE_UPDATE_ESTIMATE,
    INIT_ESTIMATE_FIELDS,
    REMOVE_PRICE_ITEM,
    REQUEST_FETCH_ESTIMATIONS,
    REQUEST_UPDATE_ESTIMATE,
    SUCCESS_FETCH_ESTIMATIONS,
    SUCCESS_UPDATE_ESTIMATE,
    UPDATE_ESTIMATE_FIELD
} from "../actionTypes";
import { reloadDetails } from "../helpers";
import { setNextFilters } from "./estimations";
import { makeSafeNumber } from "~/packages/utils/applyCurrency";

export function appendPriceItem({ pType, cType, props }) {
    let nextItem = {
        ...priceItem(cType),
        shortId: getShortId()
    };

    // 디폴트(유저가 선택한) 수리부위 추가
    if (props?.part && props?.part_code) {
        nextItem = {
            ...nextItem,
            ...props
        };
    }

    if (cType === "option") {
        nextItem = {
            ...nextItem,
            ...props
        };
    }

    return {
        type: APPEND_PRICE_ITEM,
        payload: {
            item: nextItem,
            pType
        }
    };
}

/**
 * 고객이 요청한 서비스 디폴트 값을 넣어주기 위함
 * @param {*} priceItem props of appendPriceItem
 */
export function setDefaultAppendPriceItem(priceItem) {
    return dispatch => {
        dispatch(appendPriceItem(priceItem));
    };
}

export function removePriceItem({ pType, shortId }) {
    return {
        type: REMOVE_PRICE_ITEM,
        payload: {
            pType,
            shortId
        }
    };
}

/**
 * 고객이 요청한 서비스 디폴트 값을 넣어주기 위함 - 임시저장 상태인 경우 필수값 추가용
 * @param {*} priceItem props of appendPriceItem
 */
export function updateDefaultAppendPriceItem(priceItem, pType) {
    if (!priceItem) return;

    return dispatch => {
        dispatch(removePriceItem({ pType, shortId: priceItem.shortId }));

        dispatch({
            type: APPEND_PRICE_ITEM,
            payload: {
                item: {
                    ...priceItem,
                    isRequired: true
                },
                pType
            }
        });
    };
}

export function initEstimateFields(estimateFields) {
    // for Number localeString
    const priceItems = Object.entries(estimateFields.priceItems).map(([key, value]) => ({
        key,
        value: value.map(item => {
            return {
                ...item,
                price: makeSafeNumber(item?.price.toString().replace(/[^0-9]/g, "")).toLocaleString("ko-KR")
            };
        })
    }));
    const parsedEstimateFields = {
        ...estimateFields,
        priceItems: priceItems?.reduce((prev, current) => {
            return { ...prev, [current.key]: current.value };
        }, {})
    };

    return {
        type: INIT_ESTIMATE_FIELDS,
        payload: parsedEstimateFields
    };
}

export function updateEstimateField({ name, value }) {
    return (dispatch, getState) => {
        const {
            formEstimate: { form }
        } = getState();

        dispatch({
            type: UPDATE_ESTIMATE_FIELD,
            payload: assocPath(parsePath(name), value, form)
        });
    };
}

export function updateEstimate(statusCode, sendType, isReload = true) {
    return async (dispatch, getState, api) => {
        const {
            details: { estId },
            formEstimate: { form }
        } = getState();

        const updateForm = {
            ...form,
            // 사장님이 선택한 견적서 입력 정보만 보내도록 함
            priceItems: {
                [sendType]: form.priceItems[sendType]
            }
        };

        // number localeString(, 처리) 을 위한 workaround
        const priceItems = Object.entries(updateForm.priceItems).map(([key, value]) => ({
            key,
            value: value.map(item => {
                return { ...item, price: makeSafeNumber(item?.price.toString().replace(/[^0-9]/g, "")) };
            })
        }));
        const deductibleItem = { ...updateForm.deductibleItem, price: makeSafeNumber(updateForm.deductibleItem.price) };
        const parsedUpdateForm = {
            ...updateForm,
            priceItems: priceItems?.reduce((prev, current) => {
                return { ...prev, [current.key]: current.value };
            }, {}),
            deductibleItem
        };

        dispatch({
            type: REQUEST_UPDATE_ESTIMATE
        });

        try {
            await api.updateEstimate({
                data: { ...parsedUpdateForm, statuscode: statusCode },
                estId
            });

            dispatch({
                type: SUCCESS_UPDATE_ESTIMATE
            });

            if (isReload) {
                reloadDetails(dispatch, estId);
                const path = window.location.href;
                const pathSplit = path.split("/");
                const pageNumber = pathSplit[pathSplit.length - 2];
                const {
                    user: { shopid: shopId },
                    formFilters: {
                        form: { statusCode, ...filters }
                    }
                } = getState();
                dispatch({
                    type: REQUEST_FETCH_ESTIMATIONS
                });

                const nextFilters = setNextFilters(filters);

                if (shopId) {
                    const estimations = await api.fetchEstimations({
                        filters: nextFilters,
                        statusCode,
                        shopId,
                        page: pageNumber
                    });

                    dispatch({
                        type: SUCCESS_FETCH_ESTIMATIONS,
                        payload: estimations
                    });
                }
            }
        } catch (err) {
            dispatch({
                type: FAILURE_UPDATE_ESTIMATE,
                payload: err
            });

            Swal.fire({
                position: "top",
                icon: "error",
                title: err?.response?.data?.error?.error_msg,
                showConfirmButton: false,
                timer: 1500
            });
        }
    };
}
