/**
 * Create booking information structure (API)
 * @param  {String} estId
 * @param  {Object} estimation
 * @param  {Object} shop
 * @return {Object}
 */
function bookingInfo({ estId, estimation, shop }) {
    const { regdttm, bookdttm, bookmobile, pickuparea } = estimation;

    return {
        estid: estId,
        bookdttm: bookdttm === "0000-00-00 00:00:00" ? regdttm : bookdttm,
        bookmobile,
        pickuparea
    };
}

export default bookingInfo;
