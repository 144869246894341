import { UPDATE_LOGIN_FIELD } from "../actionTypes";

export function updateLoginField({ name, value }) {
    return {
        type: UPDATE_LOGIN_FIELD,
        payload: {
            name,
            value
        }
    };
}
