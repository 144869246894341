import { bookingInfo, computeReleaseDate, estimate } from "~/core";
import { convertDateFormatAsDB, getShortId, resolveDateString } from "~/packages/utils";
import {
    FAILURE_FETCH_ESTIMATION,
    OPEN_CREATE_OFTEN_TEXT_ESTIMATION,
    OPEN_OFTEN_TEXT_DETAIL_ESTIMATION,
    REQUEST_FETCH_ESTIMATION,
    REQUEST_FETCH_OFTEN_TEXT_ESTIMATION,
    SUCCESS_FETCH_ESTIMATION
} from "../actionTypes";
import { initBookingInfoFields } from "./form-booking";
import { initEstimateFields } from "./form-estimate";
import { initInvoice, updateInvoiceField } from "./form-invoice";
import { getUnreadNotiCount } from "./initialization";

/**
 * @author 장건우
 * @description
 * 1. 유저가 알림이 존재하는 견적서를 선택하면, 서버에서 해당 견적서와 관련있는 알림들을 읽음 처리.
 * 2. 서버와 동기화 시키기위해서 클라이언트에서는 알림 카운트 항상 업데이트
 */
const updateNotificationCount = async (api, dispatch, estId) => {
    try {
        await api.patchAppointmentsNotificationRead(estId);
        dispatch(getUnreadNotiCount());
    } catch (e) {
        console.log(e);
    }
};

export function fetchEstimation(estid) {
    return async (dispatch, getState, api) => {
        dispatch({
            type: REQUEST_FETCH_ESTIMATION
        });
        try {
            const {
                estimationReq: { user_car: userCar, repaircase: repairCase, ...estReq },
                logEstimationStatus: timeline,
                estid: estId,
                shop,
                locations,
                price,
                ...estimation
            } = await api.fetchEstimation(estid);

            const {
                priceItems: { cash = [], insurance = [] },
                ...estimateFields
            } = estimate({
                estid: estId,
                estimation,
                price,
                estReq
            });

            dispatch(
                initEstimateFields({
                    ...estimateFields,
                    priceItems: {
                        cash: cash.map(item => {
                            return {
                                ...item,
                                shortId: getShortId()
                            };
                        }),
                        insurance: insurance.map(item => {
                            return {
                                ...item,
                                shortId: getShortId()
                            };
                        })
                    }
                })
            );

            dispatch({
                type: SUCCESS_FETCH_ESTIMATION,
                payload: {
                    estId,
                    shop,
                    timeline,
                    estimation,
                    price,
                    estReq,
                    userCar,
                    repairCase,
                    locations
                }
            });

            const bookingFields = bookingInfo({
                estId,
                estimation,
                shop
            });
            dispatch(initBookingInfoFields(bookingFields));

            dispatch(
                initInvoice({
                    hashedid: estimation.hashedid,
                    estid: estId,
                    reqid: estReq.reqid,
                    statement: {
                        estimationtype: "",
                        release_date: ""
                    }
                })
            );

            if (bookingFields.bookdttm) {
                const resolvedDate = resolveDateString(bookingFields.bookdttm);
                const releaseDate = computeReleaseDate({
                    period: estimation.period,
                    resolvedDate
                });
                const [formattedDate] = convertDateFormatAsDB(releaseDate).split(" ");

                dispatch(
                    updateInvoiceField({
                        name: "release_date",
                        value: `${formattedDate} 00:00:00`
                    })
                );
            }
        } catch (err) {
            dispatch({
                type: FAILURE_FETCH_ESTIMATION,
                payload: err
            });
        }

        updateNotificationCount(api, dispatch, estid);
    };
}

/** @desc estimation often text actions */

export function requestOftenTextList() {
    return async (dispatch, getState, api) => {
        const {
            details: {
                shop: { shopid: shopId }
            }
        } = getState();

        const result = await api.getEstimationOftenTexts(shopId);
        dispatch({
            type: REQUEST_FETCH_OFTEN_TEXT_ESTIMATION,
            payload: result.data ?? []
        });
    };
}

export function requestOftenTextDetail(descId) {
    return async (dispatch, getState, api) => {
        const {
            details: {
                shop: { shopid: shopId }
            }
        } = getState();

        const result = await api.getEstimationOftenTextDetail(descId, shopId);
        dispatch({
            type: OPEN_OFTEN_TEXT_DETAIL_ESTIMATION,
            payload: result.data
        });
    };
}

export function updateOftenText(descId, newText) {
    return async (dispatch, getState, api) => {
        const {
            details: {
                shop: { shopid: shopId }
            }
        } = getState();

        await api.updateEstimationOftenTextDetail(descId, shopId, newText);
        const result = await api.getEstimationOftenTexts(shopId);
        dispatch({
            type: OPEN_CREATE_OFTEN_TEXT_ESTIMATION
        });
        dispatch({
            type: REQUEST_FETCH_OFTEN_TEXT_ESTIMATION,
            payload: result.data ?? []
        });
    };
}

export function createOftenText(newText) {
    return async (dispatch, getState, api) => {
        const {
            details: {
                shop: { shopid: shopId }
            }
        } = getState();

        await api.createEstimationOftenText(shopId, newText);
        const result = await api.getEstimationOftenTexts(shopId);
        dispatch({
            type: OPEN_CREATE_OFTEN_TEXT_ESTIMATION
        });
        dispatch({
            type: REQUEST_FETCH_OFTEN_TEXT_ESTIMATION,
            payload: result.data ?? []
        });
    };
}

export function deleteOftenText(descId) {
    return async (dispatch, getState, api) => {
        const {
            details: {
                shop: { shopid: shopId }
            }
        } = getState();

        await api.deleteEstimationOftenTextDetail(descId, shopId);
        const result = await api.getEstimationOftenTexts(shopId);
        dispatch({
            type: OPEN_CREATE_OFTEN_TEXT_ESTIMATION
        });
        dispatch({
            type: REQUEST_FETCH_OFTEN_TEXT_ESTIMATION,
            payload: result.data ?? []
        });
    };
}
