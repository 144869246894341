import { FAILURE_FETCH_ADVERTISEMENT, REQUEST_FETCH_ADVERTISEMENT, SUCCESS_FETCH_ADVERTISEMENT } from "../actionTypes";

export function fetchLocalAdvertisementList(shopId) {
    return async (dispatch, getState, api) => {
        try {
            dispatch({
                type: REQUEST_FETCH_ADVERTISEMENT
            });

            const localAdvertisementList = await api.fetchLocalAdvertisementList({
                shopId
            });

            dispatch({
                type: SUCCESS_FETCH_ADVERTISEMENT,
                payload: localAdvertisementList
            });
        } catch (err) {
            dispatch({
                type: FAILURE_FETCH_ADVERTISEMENT,
                payload: err
            });
        }
    };
}
