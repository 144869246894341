import { connect } from "react-redux";
import Menu from "~/components/Menu";
import { AD, ANNOUNCEMENT, CASH, LIST, MY_INFO, NOTI, REGIONAL_SETTINGS, REPAIR_CASE, REVIEW } from "~/routes";
import { getBalance, getUnreadNotiCount, logout, setShopByAdmin } from "~/store/actions";

function mapStateToProps({
    assets: {
        shops: { list: repairShops }
    },
    user: { loginid, adminLoginId, shopid, shopname, type, businessType },
    initialization: { unreadNotiCount, cardocCash }
}) {
    const isAdmin = type === "admin";
    const menu = {
        list: {
            name: "견적",
            to: LIST
        },
        regionalSettings: {
            name: "지역설정",
            to: `${REGIONAL_SETTINGS}/${shopid}`
        },
        review: {
            name: "후기",
            to: REVIEW
        },
        portfolio: {
            name: "수리사례",
            to: `${REPAIR_CASE}/${shopid}`
        },
        cash: {
            name: "캐시관리",
            to: `${CASH}/${shopid}`
        },
        ad: {
            name: "광고",
            to: AD
        },
        noti: {
            name: "알림",
            to: NOTI
        },
        announce: {
            name: "공지사항",
            to: ANNOUNCEMENT
        },
        myInfo: {
            name: "내정보",
            to: `${MY_INFO}/${shopid}`
        },
        counseling: {
            name: "1:1 문의",
            to: "" // open iframe event from "components/Menu"
        }
    };

    return {
        shops: repairShops,
        menu,
        isAdmin,
        loginid,
        adminLoginId,
        shopid,
        shopname,
        businessType,
        unreadNotiCount,
        cardocCash
    };
}

export default connect(mapStateToProps, {
    logout,
    setShopByAdmin,
    getUnreadNotiCount,
    getBalance
})(Menu);
