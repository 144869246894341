import { defaultTheme } from "evergreen-ui";
import "./app.css";

const theme = {
    ...defaultTheme,

    ubBtnStyle: {
        height: 32,
        marginleft: 3,
        marginRight: 3,
        paddingLeft: 16,
        paddingRight: 16,
        borderRadius: 3,
        fontSize: 12
    },

    cellWidth: (no, isCenter = false) => {
        return {
            flexBasis: no,
            flexShrink: 0,
            flexGrow: 0,
            textAlign: isCenter ? "center" : "left"
        };
    }
};

export default theme;
