export const DEFAULT_THUMBNAIL_SIZE = "w222";
export const DATE_FORMAT = "yyyy-MM-dd";
export const TMR = new Date().setDate(new Date().getDate() + 1);
export const YESTERDAY = new Date().setDate(new Date().getDate() - 1);
export const THUMNAIL_SIZE = "222w";
export const NONE = "없음";
export const INSURANCE = "보험";
export const NON_INSURANCE = "비보험";
export const NON_ASSIGNED = "할당 안됨";
export const CURRENCY_UNIT = "원";
export const SELF_PAID = "부담"; //
export const PAID_MARK = "!!!badge!!!";

export const TODAY = new Date();
export const YEAR = new Date().getFullYear();
export const MONTH = new Date().getMonth() + 1;
export const DATE = new Date().getDate();
export const END_DATE_DEFAULT = new Date();
export const START_DATE_DEFAULT = new Date().setMonth(TODAY.getMonth() - 1);
