import React, { memo } from "react";
import PropTypes from "prop-types";
import { Spinner } from "evergreen-ui";
import FlexMiddle from "./FlexMiddle";

function Loading({ size, ...props }) {
    return (
        <FlexMiddle {...props}>
            <Spinner size={size} />
        </FlexMiddle>
    );
}

Loading.propTypes = {
    size: PropTypes.number
};

Loading.defaultProps = {
    size: 40
};

export default memo(Loading);
