import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useWindowSize } from "~/packages/hooks/useWindowSize";
import { DESKTOP_MIN_WIDTH } from "~/packages/utils";
import {
    CASH,
    CASH_CARD_REGISTRATION,
    CASH_HISTORY,
    CASH_RECHARGE,
    LOGIN,
    PASSWORD_RESET,
    REGIONAL_SETTINGS,
    REPAIR_CASE,
    REPAIR_CASE_WRITE
} from "~/routes";

function usePageVariables() {
    const { pathname } = useLocation();
    const { width } = useWindowSize();
    const isDesktopWidth = width >= DESKTOP_MIN_WIDTH;
    const firstPathname = useMemo(() => {
        return `/${pathname.split("/")[1]}`;
    }, [pathname]);

    const isPublicPage = useMemo(() => [LOGIN, PASSWORD_RESET].includes(firstPathname), [firstPathname]);
    const isHideMenuPage = useMemo(() => [LOGIN, PASSWORD_RESET].includes(firstPathname), [firstPathname]);

    const isResponsivePage = useMemo(
        () =>
            [
                LOGIN,
                REGIONAL_SETTINGS,
                REPAIR_CASE,
                REPAIR_CASE_WRITE,
                CASH,
                CASH_HISTORY,
                CASH_RECHARGE,
                CASH_CARD_REGISTRATION
            ].includes(firstPathname),
        [firstPathname]
    );

    const isShowDesktopMenuPage = useMemo(() => {
        if (isHideMenuPage) return false;
        if (!isResponsivePage) return true;
        return isDesktopWidth;
    }, [isHideMenuPage, isResponsivePage, isDesktopWidth]);

    const isShowMobileMenuPage = useMemo(
        () =>
            [
                REGIONAL_SETTINGS,
                REPAIR_CASE,
                REPAIR_CASE_WRITE,
                CASH,
                CASH_HISTORY,
                CASH_RECHARGE,
                CASH_CARD_REGISTRATION
            ].includes(firstPathname) && !isDesktopWidth,
        [firstPathname, isDesktopWidth]
    );

    return {
        isPublicPage,
        isHideMenuPage,
        isResponsivePage,
        isShowDesktopMenuPage,
        isShowMobileMenuPage
    };
}

export default usePageVariables;
