import { keys, map, filter, join, compose } from "ramda";

function createSrcSet(propName = "thumb_w") {
    return (dict = {}) =>
        compose(
            join(" ,"),
            filter(val => val),
            map(key => {
                const val = dict[key];

                if (key.startsWith(propName) && val.search(/jpe?g|png|gif$/i) > 1) {
                    const [, width] = key.match(new RegExp(`${propName}([0-9]{3,4})`));

                    return `${val} ${width}w`;
                }

                return "";
            }),
            keys
        )(dict);
}

const generateSrcSet = createSrcSet();
generateSrcSet.withKey = createSrcSet;

export default generateSrcSet;
