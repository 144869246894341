import {
    FAILURE_FETCH_CAR_BRAND_TYPE_LIST,
    FAILURE_FETCH_REPAIR_CASE,
    FAILURE_FETCH_REPAIR_CASES,
    FAILURE_FETCH_REPAIR_PARTS_LIST,
    FAILURE_FETCH_SHOP_REPAIR_LIST,
    FAILURE_GET_REPAIR_CASE_COUNT,
    INIT_REPAIR_CASE_DATA,
    REQUEST_FETCH_CAR_BRAND_TYPE_LIST,
    REQUEST_FETCH_REPAIR_CASE,
    REQUEST_FETCH_REPAIR_CASES,
    REQUEST_FETCH_REPAIR_PARTS_LIST,
    REQUEST_FETCH_SHOP_REPAIR_LIST,
    REQUEST_GET_REPAIR_CASE_COUNT,
    SUCCESS_FETCH_CAR_BRAND_TYPE_LIST,
    SUCCESS_FETCH_REPAIR_CASE,
    SUCCESS_FETCH_REPAIR_CASES,
    SUCCESS_FETCH_REPAIR_PARTS_LIST,
    SUCCESS_FETCH_SHOP_REPAIR_LIST,
    SUCCESS_GET_REPAIR_CASE_COUNT
} from "../actionTypes";

const initialState = {
    isLoading: false,
    isLoaded: false,
    repairCaseList: [],
    repairCaseData: {},
    repairCaseTotalCount: 0,
    shopRepairList: [],
    repairPartList: [],
    carBrandTypeList: [],
    error: null
};

function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case REQUEST_FETCH_REPAIR_CASES: {
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                error: null
            };
        }

        case FAILURE_FETCH_REPAIR_CASES: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                repairCaseList: [],
                error: payload
            };
        }

        case SUCCESS_FETCH_REPAIR_CASES: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                repairCaseList: payload,
                error: null
            };
        }

        case REQUEST_FETCH_REPAIR_CASE: {
            return {
                ...state,
                isLoading: true,
                error: null
            };
        }

        case FAILURE_FETCH_REPAIR_CASE: {
            return {
                ...state,
                isLoading: false,
                repairCaseData: {},
                error: payload
            };
        }

        case SUCCESS_FETCH_REPAIR_CASE: {
            return {
                ...state,
                isLoading: false,
                repairCaseData: payload,
                error: null
            };
        }

        case REQUEST_GET_REPAIR_CASE_COUNT: {
            return {
                ...state,
                error: null
            };
        }

        case FAILURE_GET_REPAIR_CASE_COUNT: {
            return {
                ...state,
                repairCaseTotalCount: 0,
                error: payload.error
            };
        }

        case SUCCESS_GET_REPAIR_CASE_COUNT: {
            return {
                ...state,
                repairCaseTotalCount: payload,
                error: null
            };
        }

        case REQUEST_FETCH_SHOP_REPAIR_LIST: {
            return {
                ...state,
                isLoading: true,
                error: null
            };
        }

        case FAILURE_FETCH_SHOP_REPAIR_LIST: {
            return {
                ...state,
                shopRepairList: [],
                error: payload.error
            };
        }

        case SUCCESS_FETCH_SHOP_REPAIR_LIST: {
            return {
                ...state,
                shopRepairList: payload,
                error: null
            };
        }

        case REQUEST_FETCH_REPAIR_PARTS_LIST: {
            return {
                ...state,
                isLoading: true,
                error: null
            };
        }

        case FAILURE_FETCH_REPAIR_PARTS_LIST: {
            return {
                ...state,
                repairPartList: [],
                error: payload.error
            };
        }

        case SUCCESS_FETCH_REPAIR_PARTS_LIST: {
            return {
                ...state,
                repairPartList: payload,
                error: null
            };
        }

        case REQUEST_FETCH_CAR_BRAND_TYPE_LIST: {
            return {
                ...state,
                isLoading: true,
                error: null
            };
        }

        case FAILURE_FETCH_CAR_BRAND_TYPE_LIST: {
            return {
                ...state,
                carBrandTypeList: [],
                error: payload.error
            };
        }

        case SUCCESS_FETCH_CAR_BRAND_TYPE_LIST: {
            return {
                ...state,
                carBrandTypeList: payload,
                error: null
            };
        }

        case INIT_REPAIR_CASE_DATA: {
            return {
                ...state,
                repairCaseData: {},
                error: null
            };
        }

        default: {
            return state;
        }
    }
}

export default reducer;
