import { FAILURE_FETCH_NOTIFICATION, REQUEST_FETCH_NOTIFICATION, SUCCESS_FETCH_NOTIFICATION } from "../actionTypes";

const initialState = {
    isLoading: false,
    isLoaded: false,
    notiData: [],
    count: 0,
    error: null
};

function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case REQUEST_FETCH_NOTIFICATION: {
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                error: null
            };
        }

        case FAILURE_FETCH_NOTIFICATION: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                notiData: [],
                error: payload
            };
        }

        case SUCCESS_FETCH_NOTIFICATION: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                notiData: payload.data,
                count: payload.meta.count,
                error: null
            };
        }

        default: {
            return state;
        }
    }
}

export default reducer;
