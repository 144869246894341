import { DateTime } from "luxon";
import { nowDateTime } from "../../packages/utils";

import {
    REQUEST_CANCEL_BOOKING,
    FAILURE_CANCEL_BOOKING,
    SUCCESS_CANCEL_BOOKING,
    REQUEST_UPDATE_BOOKING,
    FAILURE_UPDATE_BOOKING,
    SUCCESS_UPDATE_BOOKING,
    REQUEST_CONFIRM_BOOKING,
    FAILURE_CONFIRM_BOOKING,
    SUCCESS_CONFIRM_BOOKING,
    INIT_BOOKING_INFO_FIELDS,
    UPDATE_BOOKING_INFO_FIELD
} from "../actionTypes";
import { reloadDetails } from "../helpers";

export function initBookingInfoFields(fields) {
    return {
        type: INIT_BOOKING_INFO_FIELDS,
        payload: fields
    };
}

export function updateBookingInfoField({ name, value }) {
    return {
        type: UPDATE_BOOKING_INFO_FIELD,
        payload: {
            name,
            value
        }
    };
}

export function cancelBooking() {
    return async (dispatch, getState, api) => {
        const {
            details: { estId },
            formBooking: { form }
        } = getState();

        dispatch({
            type: REQUEST_CANCEL_BOOKING
        });

        try {
            await api.cancelBooking({
                data: {
                    ajaxcalltype: "cancelbookbyshop",
                    ...form
                },
                estId
            });

            dispatch({
                type: SUCCESS_CANCEL_BOOKING
            });

            reloadDetails(dispatch, estId);
        } catch (err) {
            dispatch({
                type: FAILURE_CANCEL_BOOKING,
                payload: err
            });
        }
    };
}

const bookdttmTransform = bookdttm => {
    const format = "yyyy-MM-dd HH:mm:ss";
    return bookdttm
        ? DateTime.fromJSDate(bookdttm instanceof Date ? bookdttm : new Date(bookdttm)).toFormat(format)
        : nowDateTime(format);
};

export function updateBooking() {
    return async (dispatch, getState, api) => {
        const {
            details: { estId },
            formBooking: { form }
        } = getState();

        dispatch({
            type: REQUEST_UPDATE_BOOKING
        });

        const bookdttm = bookdttmTransform(form.bookdttm);
        try {
            await api.updateBooking({
                data: {
                    ajaxcalltype: "modifybookbyshop",
                    ...form,
                    bookdttm
                },
                estId
            });

            dispatch({
                type: SUCCESS_UPDATE_BOOKING
            });

            reloadDetails(dispatch, estId);
        } catch (err) {
            dispatch({
                type: FAILURE_UPDATE_BOOKING,
                payload: err
            });
        }
    };
}

export function confirmBooking() {
    return async (dispatch, getState, api) => {
        const {
            details: { estId },
            formBooking: { form }
        } = getState();

        dispatch({
            type: REQUEST_CONFIRM_BOOKING
        });

        const bookdttm = bookdttmTransform(form.bookdttm);

        try {
            await api.confirmBooking({
                data: {
                    ajaxcalltype: "modifybookbyshop",
                    ...form,
                    bookdttm
                },
                estId
            });

            dispatch({
                type: SUCCESS_CONFIRM_BOOKING
            });

            reloadDetails(dispatch, estId);
        } catch (err) {
            dispatch({
                type: FAILURE_CONFIRM_BOOKING,
                payload: err
            });
        }
    };
}
