import { RESET_FILTERS, UPDATE_FILTER } from "../actionTypes";

const initialState = {
    form: {
        statusCode: "0",
        searchType: "reqid",
        searchWord: "",
        startRegDate: "",
        endRegDate: "",
        collisionCoverageYn: "",
        isCashback: "N"
    }
};

function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case UPDATE_FILTER: {
            return {
                ...state,
                form: {
                    ...state.form,
                    [payload.name]: payload.value
                }
            };
        }

        case RESET_FILTERS: {
            return {
                ...state,
                ...initialState
            };
        }

        default: {
            return state;
        }
    }
}

export default reducer;
