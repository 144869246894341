/**
 * Detect whether shop manager or not (otherwise user)
 * @param  {Boolean} [isOneSelf=false] database column name
 * @return {Boolean}
 */
function detectShopManager(isOneSelf = false) {
    return !isOneSelf;
}

export default detectShopManager;
