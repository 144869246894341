import { genericError } from "~/packages/utils";
import {
    FAILURE_GET_SHOP_ASSIGN_INFO,
    REQUEST_GET_SHOP_ASSIGN_INFO,
    SUCCESS_GET_SHOP_ASSIGN_INFO
} from "../actionTypes";

export function fetchShopAssignInfo(shopId) {
    return async (dispatch, _, api) => {
        try {
            if (!shopId) {
                throw genericError("no shopId");
            }

            dispatch({
                type: REQUEST_GET_SHOP_ASSIGN_INFO
            });

            if (shopId) {
                const res = await api.getShopAssignInfo(shopId);

                dispatch({
                    type: SUCCESS_GET_SHOP_ASSIGN_INFO,
                    payload: res
                });
            }
        } catch (err) {
            dispatch({
                type: FAILURE_GET_SHOP_ASSIGN_INFO,
                payload: err
            });
        }
    };
}
