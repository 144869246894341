import {
    REQUEST_UPLOAD_IMAGES,
    FAILURE_UPLOAD_IMAGES,
    SUCCESS_UPLOAD_IMAGES,
    REQUEST_SUBMIT_CONFIRMATION,
    FAILURE_SUBMIT_CONFIRMATION,
    SUCCESS_SUBMIT_CONFIRMATION,
    UPDATE_CONFIRMATION_FIELD
} from "../actionTypes";

const initialState = {
    isLoading: false,
    isLoaded: false,
    form: {
        updatetype: "finish_repair",
        confirm_payatshop: false
    },
    error: null
};

function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case UPDATE_CONFIRMATION_FIELD: {
            return {
                ...state,
                form: {
                    ...state.form,
                    [payload.name]: payload.value
                }
            };
        }

        case REQUEST_UPLOAD_IMAGES: {
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                error: null
            };
        }

        case FAILURE_UPLOAD_IMAGES: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: payload
            };
        }

        case SUCCESS_UPLOAD_IMAGES: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                form: {
                    ...state.form
                },
                error: null
            };
        }

        case REQUEST_SUBMIT_CONFIRMATION: {
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                error: null
            };
        }

        case FAILURE_SUBMIT_CONFIRMATION: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: payload
            };
        }

        case SUCCESS_SUBMIT_CONFIRMATION: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                form: {
                    updatetype: "finish_repair",
                    confirm_payatshop: false
                },
                error: null
            };
        }

        default: {
            return state;
        }
    }
}

export default reducer;
