import {
    FAILURE_GET_CARDOC_CASH,
    FAILURE_GET_UNREAD_NOTI_COUNT,
    REQUEST_GET_CARDOC_CASH,
    REQUEST_GET_UNREAD_NOTI_COUNT,
    SUCCESS_GET_CARDOC_CASH,
    SUCCESS_GET_UNREAD_NOTI_COUNT
} from "../actionTypes";

const initialState = {
    unreadNotiCount: 0,
    cardocCash: 0,
    error: null
};

function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case REQUEST_GET_UNREAD_NOTI_COUNT: {
            return {
                ...state,
                error: null
            };
        }

        case FAILURE_GET_UNREAD_NOTI_COUNT: {
            return {
                ...state,
                unreadNotiCount: -1,
                error: payload.error
            };
        }

        case SUCCESS_GET_UNREAD_NOTI_COUNT: {
            return {
                ...state,
                unreadNotiCount: payload.data,
                error: null
            };
        }

        case REQUEST_GET_CARDOC_CASH: {
            return {
                ...state,
                cardocCash: 0,
                error: null
            };
        }

        case FAILURE_GET_CARDOC_CASH: {
            return {
                ...state,
                cardocCash: null,
                error: payload.error
            };
        }

        case SUCCESS_GET_CARDOC_CASH: {
            return {
                ...state,
                cardocCash: parseInt(payload?.total_balance, 10) ?? 0,
                error: null
            };
        }

        default: {
            return state;
        }
    }
}

export default reducer;
