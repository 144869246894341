import { genericError } from "~/packages/utils";
import { END_DATE_DEFAULT, START_DATE_DEFAULT } from "~/presets";
import {
    FAILURE_GET_CASH_HISTORY,
    FAILURE_GET_CASH_HISTORY_COUNT,
    REQUEST_GET_CASH_HISTORY,
    REQUEST_GET_CASH_HISTORY_COUNT,
    SUCCESS_GET_CASH_HISTORY,
    SUCCESS_GET_CASH_HISTORY_COUNT
} from "../actionTypes";

function convertDateFormat(date) {
    const timezoneOffset = new Date().getTimezoneOffset() * 60000;
    const timezoneDate = new Date(+new Date(date) - timezoneOffset);
    const [formattedDate] = timezoneDate.toISOString().split("T");

    return formattedDate;
}

// shop 캐시 관련
export function fetchCardocCashTx({
    subjectName = "periodicSummary",
    aggregation = "daily",
    startDate = START_DATE_DEFAULT,
    endDate = END_DATE_DEFAULT,
    page = 1,
    pageSize = 10,
    isPaidChargeOnly = false
}) {
    return async (dispatch, getState, api) => {
        const {
            user: { shopid: shopId }
        } = getState();

        try {
            if (!shopId) {
                throw genericError("no shopid");
            }
            dispatch({
                type: REQUEST_GET_CASH_HISTORY
            });

            startDate = convertDateFormat(startDate);
            endDate = convertDateFormat(endDate);

            let cash = [];
            // 기간별 내역
            switch (subjectName) {
                case "periodicSummary":
                    cash = await api.getCardocCashPeriodSummaryList(
                        shopId,
                        aggregation,
                        startDate,
                        endDate,
                        page,
                        pageSize
                    );
                    break;
                case "chargeHistory": // 충전 내역
                    cash = await api.getCardocCashChargeHistoryList(
                        shopId,
                        startDate,
                        endDate,
                        page,
                        pageSize,
                        isPaidChargeOnly
                    );
                    break;
                case "useHistory": // 거래 내역
                    cash = await api.getCardocCashUseHistoryList(shopId, startDate, endDate);
                    break;
                case "cashBackList": // 견적 수신 내역
                    cash = await api.getCardocCashCashBackList(shopId, startDate, endDate, page, pageSize);
                    break;
                case "appPayment": // 앱 결제 관리
                    cash = await api.getCardocCashAppPayment(shopId, page, pageSize);
                    break;
                default:
                    break;
            }

            dispatch({
                type: SUCCESS_GET_CASH_HISTORY,
                payload: cash
            });
        } catch (err) {
            dispatch({
                type: FAILURE_GET_CASH_HISTORY,
                payload: err
            });
        }
    };
}

export function fetchCardocCashTxCount({
    subjectName = "periodicSummary",
    aggregation = "daily",
    startDate = START_DATE_DEFAULT,
    endDate = END_DATE_DEFAULT,
    pageSize = 10,
    isPaidChargeOnly = false
}) {
    return async (dispatch, getState, api) => {
        const {
            user: { shopid: shopId }
        } = getState();

        try {
            if (!shopId) {
                throw genericError("no shopid");
            }

            dispatch({
                type: REQUEST_GET_CASH_HISTORY_COUNT
            });

            startDate = convertDateFormat(startDate);
            endDate = convertDateFormat(endDate);
            let count = {};
            switch (subjectName) {
                case "periodicSummary": // 기간별 내역
                    count = await api.getCardocCashPeriodSummaryCount(shopId, aggregation, startDate, endDate);
                    break;
                case "chargeHistory": // 충전 내역
                    count = await api.getCardocCashChargeHistoryCount(
                        shopId,
                        startDate,
                        endDate,
                        pageSize,
                        isPaidChargeOnly
                    );
                    break;
                case "cashBackList": // 견적 수신 내역
                    count = await api.getCardocCashCashBackCount(shopId, startDate, endDate, pageSize);
                    break;
                case "appPayment": // 앱 결제 관리
                    count = await api.getCardocCashAppPaymentCount(shopId);
                    break;
                // 거래 내역 x count
                default:
                    break;
            }

            dispatch({
                type: SUCCESS_GET_CASH_HISTORY_COUNT,
                payload: count?.item_count ?? Number(count?.count)
            });
        } catch (err) {
            dispatch({
                type: FAILURE_GET_CASH_HISTORY_COUNT,
                payload: err
            });
        }
    };
}
