import * as Sentry from "@sentry/react";
import axios from "axios";
import { IS_DEV } from "~/shared/env";
import interceptors from "./interceptors";

function createRequest(config = {}) {
    const { options = {}, onError, onSuccess } = config;
    const { timeout = 30000, baseURL, ...defOptions } = options;
    const configureIntercetpors = interceptors(config);
    const instance = axios.create({
        withCredentials: true,
        timeout,
        baseURL,
        ...defOptions
    });

    if (!IS_DEV) {
        instance.interceptors.response.use(
            response => response,
            error => {
                Sentry.setTag("api", error?.response.request.responseURL);
                Sentry.captureException(error, {
                    extra: {
                        data: error?.response.data,
                        response: error?.response,
                        request: error?.request,
                        message: error?.message
                    }
                });

                return Promise.reject(onError(error) || error);
            }
        );
    }

    return configureIntercetpors(instance);
}

export default createRequest;
