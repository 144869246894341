export { default as applyCurrency } from "./applyCurrency";
export { default as bindParams } from "./bindParams";
export { default as clean } from "./clean";
export { default as convertDateFormatAsDB } from "./convertDateFormatAsDB";
export { default as generateSrcSet } from "./generateSrcSet";
export { default as genericError } from "./genericError";
export { default as getLocalizedDateString } from "./getLocalizedDateString";
export { default as getShortId } from "./getShortId";
export { default as nowDateTime } from "./nowDateTime";
export { default as openInNewTab } from "./openInNewTab";
export { default as remove } from "./remove";
export { default as removeProtocol } from "./removeProtocol";
export { default as resolveDateString } from "./resolveDateString";
export { default as splitContent } from "./splitContent";

export const DESKTOP_MIN_WIDTH = 1200;
export const REDIRECT_QUERY_PARAM_KEY = "redirect-url";

export const cleanPath = (pathArr = []) => {
    return pathArr.filter(Boolean).join("/");
};
