import {
    FAILURE_FETCH_ESTIMATION,
    OPEN_CREATE_OFTEN_TEXT_ESTIMATION,
    OPEN_OFTEN_TEXT_DETAIL_ESTIMATION,
    REQUEST_FETCH_ESTIMATION,
    REQUEST_FETCH_OFTEN_TEXT_ESTIMATION,
    SUCCESS_FETCH_ESTIMATION
} from "../actionTypes";

const initialState = {
    isLoading: true,
    isLoaded: false,
    estId: "",
    shop: {},
    estimation: {},
    estReq: {},
    userCar: {},
    repairCase: {},
    timeline: [],
    price: [],
    locations: [],
    oftenText: {
        list: [],
        newOftenText: {
            id: "",
            content: ""
        }
    },
    error: null
};

function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case REQUEST_FETCH_ESTIMATION: {
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                estId: "",
                shop: {},
                estimation: {},
                estReq: {},
                userCar: {},
                repairCase: {},
                timeline: [],
                price: [],
                locations: [],
                error: null
            };
        }

        case FAILURE_FETCH_ESTIMATION: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                estId: "",
                shop: {},
                estimation: {},
                estReq: {},
                userCar: {},
                repairCase: {},
                timeline: [],
                price: [],
                locations: [],
                error: payload
            };
        }

        case SUCCESS_FETCH_ESTIMATION: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: null,
                ...payload
            };
        }

        case REQUEST_FETCH_OFTEN_TEXT_ESTIMATION: {
            return {
                ...state,
                oftenText: {
                    ...state.oftenText,
                    list: payload
                }
            };
        }

        case OPEN_OFTEN_TEXT_DETAIL_ESTIMATION: {
            return {
                ...state,
                oftenText: {
                    ...state.oftenText,
                    newOftenText: payload
                }
            };
        }

        case OPEN_CREATE_OFTEN_TEXT_ESTIMATION: {
            return {
                ...state,
                oftenText: {
                    ...state.oftenText,
                    newOftenText: initialState.oftenText.newOftenText
                }
            };
        }

        default: {
            return state;
        }
    }
}

export default reducer;
