import {
    REQUEST_FETCH_CATEGORY_05,
    FAILURE_FETCH_CATEGORY_05,
    SUCCESS_FETCH_CATEGORY_05,
    REQUEST_FETCH_CATEGORY_06,
    FAILURE_FETCH_CATEGORY_06,
    SUCCESS_FETCH_CATEGORY_06,
    REQUEST_FETCH_CATEGORY_07,
    FAILURE_FETCH_CATEGORY_07,
    SUCCESS_FETCH_CATEGORY_07
} from "../actionTypes";

const initialState = {
    isLoading: false,
    isLoaded: false,
    cc05: [],
    cc06: [],
    cc07: [],
    error: null
};

function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case REQUEST_FETCH_CATEGORY_05: {
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                cc05: [],
                error: null
            };
        }

        case FAILURE_FETCH_CATEGORY_05: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                cc05: [],
                error: payload
            };
        }

        case SUCCESS_FETCH_CATEGORY_05: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                cc05: payload,
                error: null
            };
        }

        case REQUEST_FETCH_CATEGORY_06: {
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                cc06: [],
                error: null
            };
        }

        case FAILURE_FETCH_CATEGORY_06: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                cc06: [],
                error: payload
            };
        }

        case SUCCESS_FETCH_CATEGORY_06: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                cc06: payload,
                error: null
            };
        }

        case REQUEST_FETCH_CATEGORY_07: {
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                cc07: [],
                error: null
            };
        }

        case FAILURE_FETCH_CATEGORY_07: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                cc07: [],
                error: payload
            };
        }

        case SUCCESS_FETCH_CATEGORY_07: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                cc07: payload,
                error: null
            };
        }

        default: {
            return state;
        }
    }
}

export default reducer;
