import Cookies from "universal-cookie";
import { EXPIRES, SID } from "~/presets";

const cookies = new Cookies({ path: "/", expires: EXPIRES });

class Cookie {
    static get(key = SID) {
        return cookies.get(key);
    }

    static set(val, key = SID) {
        cookies.set(key, val);

        return key;
    }

    static remove(key = SID) {
        cookies.remove(key);

        return key;
    }
}

export default Cookie;
