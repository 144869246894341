import {
    REQUEST_FETCH_INSTANT_ADS_INFO,
    FAILURE_FETCH_INSTANT_ADS_INFO,
    SUCCESS_FETCH_INSTANT_ADS_INFO
} from "../actionTypes";

const initialState = {
    isLoading: false,
    isLoaded: false,
    list: [],
    error: null
};

function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case REQUEST_FETCH_INSTANT_ADS_INFO: {
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                list: [],
                error: null
            };
        }

        case FAILURE_FETCH_INSTANT_ADS_INFO: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                list: [],
                error: payload
            };
        }

        case SUCCESS_FETCH_INSTANT_ADS_INFO: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                list: payload,
                error: null
            };
        }

        default: {
            return state;
        }
    }
}

export default reducer;
