import { ESTIMATE_STATUS_MAP } from "../presets";

/**
 * Detect whether booked out or not
 * @param  {Number}  statusCode
 * @return {Boolean}
 */
function detectBookedOut(statusCode) {
    return [ESTIMATE_STATUS_MAP["예약요청"], ESTIMATE_STATUS_MAP["예약확정"]].includes(statusCode);
}

export default detectBookedOut;
