import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { useEffect } from "react";
import { createRoutesFromChildren, matchRoutes, Routes, useLocation, useNavigationType } from "react-router-dom";
import { IS_DEV, MODE, SENTRY } from "~/shared/env";

export function initiateSentry() {
    if (!IS_DEV) {
        Sentry.init({
            dsn: SENTRY,
            environment: MODE,
            integrations: [
                new BrowserTracing({
                    routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                        useEffect,
                        useLocation,
                        useNavigationType,
                        createRoutesFromChildren,
                        matchRoutes
                    )
                })
            ],
            tracesSampleRate: 1.0
        });
    }
}

export const SentryRoutes = IS_DEV ? Routes : Sentry.withSentryReactRouterV6Routing(Routes);
