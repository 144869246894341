import {
    FAILURE_FETCH_REPAIR_SHOPS,
    FAILURE_FETCH_TERMS,
    REQUEST_FETCH_REPAIR_SHOPS,
    REQUEST_FETCH_TERMS,
    SUCCESS_FETCH_REPAIR_SHOPS,
    SUCCESS_FETCH_TERMS
} from "../actionTypes";

/**
 * @desc 설명 추가
 */
export function fetchTerms() {
    return async (dispatch, getState, api) => {
        dispatch({
            type: REQUEST_FETCH_TERMS
        });

        try {
            const { result } = await api.fetchTerms();
            const filteredList = result.filter(item => item.id !== "1");

            dispatch({
                type: SUCCESS_FETCH_TERMS,
                payload: filteredList
            });
        } catch (err) {
            dispatch({
                type: FAILURE_FETCH_TERMS,
                payload: err
            });
        }
    };
}

export function fetchRepairShops() {
    return async (dispatch, getState, api) => {
        dispatch({
            type: REQUEST_FETCH_REPAIR_SHOPS
        });

        try {
            const { result: shops } = await api.fetchRepairShops();

            dispatch({
                type: SUCCESS_FETCH_REPAIR_SHOPS,
                payload: shops
            });
        } catch (err) {
            dispatch({
                type: FAILURE_FETCH_REPAIR_SHOPS,
                payload: err
            });
        }
    };
}
