/**
 * Compute required tab(s) by computing API response
 * @param  {Array}   tabs
 * @param  {String?} cashYn
 * @param  {String?} insurance
 * @param  {String?} rerequest
 * @param  {Object}  paymentStatement
 * @return {Array}
 */
function computeRequiredTabs({ pay_statement: payStatement = {}, tabs, cashYn, insurance, rerequest }) {
    const { code_name: codeName } = payStatement;

    if (codeName) {
        return tabs.map(tab => {
            return {
                ...tab,
                isRequired: tab.type === codeName
            };
        });
    }

    // user request
    // if there is values, no more check after this logic
    if (rerequest) {
        return tabs.map(tab => {
            return {
                ...tab,
                isRequired: tab.type === rerequest
            };
        });
    }

    // can be both
    return tabs.map(tab => {
        if (tab.type === "cash" && cashYn === "Y") {
            return {
                ...tab,
                isRequired: true
            };
        }

        if (tab.type === "insurance" && insurance) {
            return {
                ...tab,
                isRequired: true
            };
        }

        return tab;
    });
}

export default computeRequiredTabs;
