import {
    FAILURE_CHECK_ADMIN_USER,
    FAILURE_LOGIN,
    FAILURE_LOGOUT,
    FAILURE_VERIFY_AUTH,
    REQUEST_CHECK_ADMIN_USER,
    REQUEST_LOGIN,
    REQUEST_LOGOUT,
    REQUEST_VERIFY_AUTH,
    SET_SHOP_BY_ADMIN,
    SUCCESS_CHECK_ADMIN_USER,
    SUCCESS_LOGIN,
    SUCCESS_LOGOUT,
    SUCCESS_VERIFY_AUTH
} from "../actionTypes";

const initialState = {
    isLoading: false,
    isLoaded: false,
    loginid: "",
    shopid: "",
    shopname: "",
    type: "",
    grade: "",
    accountType: "",
    accountType2: "",
    businessType: "",
    error: null
};

function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case REQUEST_CHECK_ADMIN_USER: {
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                type: "",
                error: null
            };
        }

        case FAILURE_CHECK_ADMIN_USER: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                type: "",
                error: payload
            };
        }

        case SUCCESS_CHECK_ADMIN_USER: {
            return {
                ...state,
                ...payload,
                isLoading: false,
                isLoaded: true,
                error: null
            };
        }

        case REQUEST_VERIFY_AUTH: {
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                loginid: "",
                shopid: "",
                shopname: "",
                grade: "",
                accountType: "",
                accountType2: "",
                businessType: "",
                error: null
            };
        }

        case FAILURE_VERIFY_AUTH: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: payload
            };
        }

        case SUCCESS_VERIFY_AUTH: {
            return {
                ...state,
                ...payload,
                isLoading: false,
                isLoaded: true,
                error: null
            };
        }

        case REQUEST_LOGOUT: {
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                error: null
            };
        }

        case FAILURE_LOGOUT: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: payload
            };
        }

        case SUCCESS_LOGOUT: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                adminLoginId: "",
                loginid: "",
                shopid: "",
                shopname: "",
                type: "",
                grade: "",
                accountType: "",
                accountType2: "",
                businessType: "",
                error: null
            };
        }

        case SET_SHOP_BY_ADMIN: {
            return {
                ...state,
                shopid: payload.shopid,
                shopname: payload.shopname,
                businessType: payload.business_type
            };
        }

        // TODO need to check
        case REQUEST_LOGIN: {
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                type: "",
                grade: "",
                error: null
            };
        }

        case FAILURE_LOGIN: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                type: "",
                grade: "",
                error: payload
            };
        }

        case SUCCESS_LOGIN: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                type: payload.type,
                grade: payload.grade,
                error: null
            };
        }

        default: {
            return state;
        }
    }
}

export default reducer;
