import {
    FAILURE_GET_CASH_HISTORY,
    FAILURE_GET_CASH_HISTORY_COUNT,
    REQUEST_GET_CASH_HISTORY,
    REQUEST_GET_CASH_HISTORY_COUNT,
    SUCCESS_GET_CASH_HISTORY,
    SUCCESS_GET_CASH_HISTORY_COUNT
} from "../actionTypes";

const initialState = {
    isLoading: false,
    isLoaded: false,
    list: [],
    count: 0,
    error: null
};

function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case REQUEST_GET_CASH_HISTORY: {
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                list: [],
                error: null
            };
        }

        case FAILURE_GET_CASH_HISTORY: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                list: [],
                error: payload
            };
        }

        case SUCCESS_GET_CASH_HISTORY: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                list: payload,
                error: null
            };
        }

        case REQUEST_GET_CASH_HISTORY_COUNT: {
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                count: 0,
                error: null
            };
        }

        case FAILURE_GET_CASH_HISTORY_COUNT: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                count: 0,
                error: payload
            };
        }

        case SUCCESS_GET_CASH_HISTORY_COUNT: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                count: payload,
                error: null
            };
        }

        default: {
            return state;
        }
    }
}

export default reducer;
