import detectBookedOut from "./detectBookedOut";

/**
 * Detect whether an estimate canceled or not
 * - There is no status code data exist
 * - But possible to predict with logs data
 * @param  {Array}   logs
 * @return {Boolean}
 */
function detectCanceled(logs) {
    if (!logs) {
        return false;
    }

    const canceledLog = logs.find((logItem, idx, self) => {
        const logItemStatusCode = parseInt(logItem.statuscode, 10);

        if (detectBookedOut(logItemStatusCode)) {
            const nextLog = self[idx + 1];

            if (nextLog) {
                return logItemStatusCode > parseInt(nextLog.statuscode, 10);
            }
        }

        return false;
    });

    return !!canceledLog;
}

export default detectCanceled;
