/**
 * Compute release date (simple, booked date + period)
 * @see @cardoc/repair-utils#resolveDateString
 * @param  {String} resolvedDate
 * @param  {Number} period
 * @return {Date}
 */
function computeReleaseDate({ resolvedDate, period }) {
    const bookedDate = new Date(resolvedDate);
    const ts = bookedDate.setDate(bookedDate.getDate() + period);

    return new Date(ts);
}

export default computeReleaseDate;
