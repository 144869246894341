import { convertDateFormatAsDB, genericError } from "~/packages/utils";
import {
    FAILURE_FETCH_ESTIMATIONS,
    FAILURE_FETCH_ESTIMATIONS_COUNT,
    REQUEST_FETCH_ESTIMATIONS,
    REQUEST_FETCH_ESTIMATIONS_COUNT,
    SUCCESS_FETCH_ESTIMATIONS,
    SUCCESS_FETCH_ESTIMATIONS_COUNT
} from "../actionTypes";

export function setNextFilters({ startRegDate, endRegDate, ...filters }) {
    let nextFilters = filters;

    if (startRegDate && endRegDate) {
        const [startDate] = convertDateFormatAsDB(startRegDate).split(" ");
        const [endDate] = convertDateFormatAsDB(endRegDate).split(" ");

        nextFilters = {
            ...nextFilters,
            startRegDate: startDate,
            endRegDate: endDate
        };
    }

    return nextFilters;
}

export function fetchEstimations(page) {
    return async (dispatch, getState, api) => {
        const {
            user: { shopid: shopId },
            formFilters: {
                form: { statusCode, ...filters }
            }
        } = getState();

        try {
            if (!shopId) {
                throw genericError("no shopid");
            }

            dispatch({
                type: REQUEST_FETCH_ESTIMATIONS
            });

            const nextFilters = setNextFilters(filters);

            if (shopId) {
                const estimations = await api.fetchEstimations({
                    filters: nextFilters,
                    statusCode,
                    shopId,
                    page
                });

                dispatch({
                    type: SUCCESS_FETCH_ESTIMATIONS,
                    payload: estimations
                });
            }
        } catch (err) {
            dispatch({
                type: FAILURE_FETCH_ESTIMATIONS,
                payload: err
            });
        }
    };
}

export function fetchEstimationsCount(page) {
    return async (dispatch, getState, api) => {
        const {
            user: { shopid: shopId },
            formFilters: {
                form: { statusCode, ...filters }
            }
        } = getState();

        try {
            if (!shopId) {
                throw genericError("no shopid");
            }

            dispatch({
                type: REQUEST_FETCH_ESTIMATIONS_COUNT
            });

            const nextFilters = setNextFilters(filters);

            const { pagesize: pageSize, total } = await api.fetchEstimationsCount({
                filters: nextFilters,
                statusCode,
                shopId,
                page
            });

            dispatch({
                type: SUCCESS_FETCH_ESTIMATIONS_COUNT,
                payload: {
                    total,
                    pageSize
                }
            });
        } catch (err) {
            dispatch({
                type: FAILURE_FETCH_ESTIMATIONS_COUNT,
                payload: err
            });
        }
    };
}
