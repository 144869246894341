import {
    REQUEST_FETCH_CHAT_MESSAGES,
    FAILURE_FETCH_CHAT_MESSAGES,
    SUCCESS_FETCH_CHAT_MESSAGES,
    REQUEST_SUBMIT_CHAT_MESSAGE,
    FAILURE_SUBMIT_CHAT_MESSAGE,
    SUCCESS_SUBMIT_CHAT_MESSAGE,
    REQUEST_UPLOAD_IMAGES,
    FAILURE_UPLOAD_IMAGES,
    SUCCESS_UPLOAD_IMAGES,
    REQUEST_SUBMIT_CHAT_IMAGE,
    FAILURE_SUBMIT_CHAT_IMAGE,
    SUCCESS_SUBMIT_CHAT_IMAGE
} from "../actionTypes";

export function fetchChatMessages() {
    return async (dispatch, getState, api) => {
        const {
            details: {
                estReq: { reqid: reqId },
                estId
            }
        } = getState();

        dispatch({
            type: REQUEST_FETCH_CHAT_MESSAGES
        });

        try {
            const messages = await api.fetchChatMessages({ estId, reqId });

            dispatch({
                type: SUCCESS_FETCH_CHAT_MESSAGES,
                payload: messages
            });
        } catch (err) {
            dispatch({
                type: FAILURE_FETCH_CHAT_MESSAGES,
                payload: err
            });
        }
    };
}

export function submitChatImage(ids) {
    return async (dispatch, getState, api) => {
        const {
            details: { estId }
        } = getState();

        dispatch({
            type: REQUEST_SUBMIT_CHAT_IMAGE
        });

        try {
            const message = await api.submitChatImage({ estId, image: ids[0] });

            dispatch({
                type: SUCCESS_SUBMIT_CHAT_IMAGE,
                payload: message
            });

            dispatch(fetchChatMessages());
        } catch (err) {
            dispatch({
                type: FAILURE_SUBMIT_CHAT_IMAGE,
                payload: err
            });
        }
    };
}

export function uploadImages(files) {
    return async (dispatch, getState, api) => {
        const {
            details: {
                shop: { shopid }
            }
        } = getState();

        dispatch({
            type: REQUEST_UPLOAD_IMAGES
        });

        try {
            // it is for multiple files, but 1 single file for now
            const reqs = files.map(file => api.uploadFile({ file, shopid }));
            const data = await Promise.all(reqs);
            const ids = data.map(({ fileid }) => fileid);

            dispatch({
                type: SUCCESS_UPLOAD_IMAGES
            });

            dispatch(submitChatImage(ids));
        } catch (err) {
            dispatch({
                type: FAILURE_UPLOAD_IMAGES,
                payload: err
            });
        }
    };
}

export function submitChatMessage(msg) {
    return async (dispatch, getState, api) => {
        const {
            details: { estId }
        } = getState();

        dispatch({
            type: REQUEST_SUBMIT_CHAT_MESSAGE
        });

        try {
            const message = await api.submitChatMessage({ estId, msg });

            dispatch({
                type: SUCCESS_SUBMIT_CHAT_MESSAGE,
                payload: message
            });
        } catch (err) {
            dispatch({
                type: FAILURE_SUBMIT_CHAT_MESSAGE,
                payload: err
            });
        }
    };
}
