import {
    REQUEST_CANCEL_BOOKING,
    FAILURE_CANCEL_BOOKING,
    SUCCESS_CANCEL_BOOKING,
    REQUEST_CONFIRM_BOOKING,
    FAILURE_CONFIRM_BOOKING,
    SUCCESS_CONFIRM_BOOKING,
    REQUEST_UPDATE_BOOKING,
    FAILURE_UPDATE_BOOKING,
    SUCCESS_UPDATE_BOOKING,
    INIT_BOOKING_INFO_FIELDS,
    UPDATE_BOOKING_INFO_FIELD
} from "../actionTypes";

const initialState = {
    isLoading: false,
    isLoaded: false,
    form: {
        estid: "",
        bookdttm: "",
        bookmobile: "",
        pickuparea: ""
    },
    error: null
};

function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case INIT_BOOKING_INFO_FIELDS: {
            return {
                ...state,
                form: payload
            };
        }

        case UPDATE_BOOKING_INFO_FIELD: {
            return {
                ...state,
                form: {
                    ...state.form,
                    [payload.name]: payload.value
                }
            };
        }

        case REQUEST_CANCEL_BOOKING:
        case REQUEST_CONFIRM_BOOKING:
        case REQUEST_UPDATE_BOOKING: {
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                error: null
            };
        }

        case FAILURE_CANCEL_BOOKING:
        case FAILURE_CONFIRM_BOOKING:
        case FAILURE_UPDATE_BOOKING: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: payload
            };
        }

        case SUCCESS_CANCEL_BOOKING:
        case SUCCESS_CONFIRM_BOOKING:
        case SUCCESS_UPDATE_BOOKING: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: null
            };
        }

        default: {
            return state;
        }
    }
}

export default reducer;
