import {
    FAILURE_FETCH_CAR_BRAND_TYPE_LIST,
    FAILURE_FETCH_REPAIR_CASE,
    FAILURE_FETCH_REPAIR_CASES,
    FAILURE_FETCH_REPAIR_PARTS_LIST,
    FAILURE_FETCH_SHOP_REPAIR_LIST,
    FAILURE_GET_REPAIR_CASE_COUNT,
    INIT_REPAIR_CASE_DATA,
    REQUEST_FETCH_CAR_BRAND_TYPE_LIST,
    REQUEST_FETCH_REPAIR_CASE,
    REQUEST_FETCH_REPAIR_CASES,
    REQUEST_FETCH_REPAIR_PARTS_LIST,
    REQUEST_FETCH_SHOP_REPAIR_LIST,
    REQUEST_GET_REPAIR_CASE_COUNT,
    SUCCESS_FETCH_CAR_BRAND_TYPE_LIST,
    SUCCESS_FETCH_REPAIR_CASE,
    SUCCESS_FETCH_REPAIR_CASES,
    SUCCESS_FETCH_REPAIR_PARTS_LIST,
    SUCCESS_FETCH_SHOP_REPAIR_LIST,
    SUCCESS_GET_REPAIR_CASE_COUNT
} from "../actionTypes";

export function fetchRepairCases(shopId, page) {
    return async (dispatch, getState, api) => {
        try {
            dispatch({
                type: REQUEST_FETCH_REPAIR_CASES
            });

            const repairCases = await api.fetchRepairCases({ shopId, page });

            dispatch({
                type: SUCCESS_FETCH_REPAIR_CASES,
                payload: repairCases
            });
        } catch (err) {
            dispatch({
                type: FAILURE_FETCH_REPAIR_CASES,
                payload: err
            });
        }
    };
}

export function fetchRepairCase(caseId) {
    return async (dispatch, getState, api) => {
        try {
            dispatch({
                type: REQUEST_FETCH_REPAIR_CASE
            });

            const repairCase = await api.fetchRepairCase({
                caseId
            });

            dispatch({
                type: SUCCESS_FETCH_REPAIR_CASE,
                payload: repairCase
            });
        } catch (err) {
            dispatch({
                type: FAILURE_FETCH_REPAIR_CASE,
                payload: err
            });
        }
    };
}

export function getRepairCaseCount(shopId) {
    return async (dispatch, getState, api) => {
        try {
            dispatch({
                type: REQUEST_GET_REPAIR_CASE_COUNT
            });

            const repairCaseCount = await api.getRepairCaseCount({ shopId });

            dispatch({
                type: SUCCESS_GET_REPAIR_CASE_COUNT,
                payload: repairCaseCount
            });
        } catch (err) {
            dispatch({
                type: FAILURE_GET_REPAIR_CASE_COUNT,
                payload: err
            });
        }
    };
}

export function fetchShopRepairList(shopId, estId) {
    return async (dispatch, getState, api) => {
        try {
            dispatch({
                type: REQUEST_FETCH_SHOP_REPAIR_LIST
            });

            const shopRepairList = await api.fetchShopRepairList({
                shopId,
                estId
            });

            dispatch({
                type: SUCCESS_FETCH_SHOP_REPAIR_LIST,
                payload: shopRepairList
            });
        } catch (err) {
            dispatch({
                type: FAILURE_FETCH_SHOP_REPAIR_LIST,
                payload: err
            });
        }
    };
}

export function fetchRepairPartList() {
    return async (dispatch, getState, api) => {
        try {
            dispatch({
                type: REQUEST_FETCH_REPAIR_PARTS_LIST
            });

            const repairPartList = await api.fetchRepairPartList();

            dispatch({
                type: SUCCESS_FETCH_REPAIR_PARTS_LIST,
                payload: repairPartList
            });
        } catch (err) {
            dispatch({
                type: FAILURE_FETCH_REPAIR_PARTS_LIST,
                payload: err
            });
        }
    };
}

export function fetchCarBrandTypeList() {
    return async (dispatch, getState, api) => {
        try {
            dispatch({
                type: REQUEST_FETCH_CAR_BRAND_TYPE_LIST
            });

            const carBrandTypeList = await api.fetchCarBrandTypeList();

            dispatch({
                type: SUCCESS_FETCH_CAR_BRAND_TYPE_LIST,
                payload: carBrandTypeList
            });
        } catch (err) {
            dispatch({
                type: FAILURE_FETCH_CAR_BRAND_TYPE_LIST,
                payload: err
            });
        }
    };
}

export function initRepairCaseData() {
    return {
        type: INIT_REPAIR_CASE_DATA
    };
}
