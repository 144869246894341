/**
 * Create booking information structure (API)
 * TODO refactoring code, fetch code
 * @param  {String} categoryType repair(05, 06) | part (06)
 * @return {Object}
 */
function priceItem(categoryType) {
    const defaultTypeCode = {
        repair: "01",
        part: "02",
        option: "03"
    };

    const defaultPart = {
        repair: "",
        part: "순정",
        option: ""
    };

    const defaultPartCode = {
        repair: "",
        part: "01",
        option: ""
    };

    const defaultPartCategory = {
        repair: "05",
        part: "07",
        option: ""
    };

    // const defaultSolution = {
    //   repair: '판금/보수도장',
    //   part: '',
    //   option: '',
    // };

    // const defaultSolutionCode = {
    //   repair: '01',
    //   part: '',
    //   option: '',
    // };

    const defaultSolutionCategory = {
        repair: "06",
        part: "",
        option: ""
    };

    const defaultOptionCategory = {
        repair: "",
        solution: "",
        option: "08"
    };

    return {
        type: categoryType,
        type_code: defaultTypeCode[categoryType],
        part: defaultPart[categoryType],
        part_code: defaultPartCode[categoryType],
        part_code_category: defaultPartCategory[categoryType],
        part_etc: null,
        solution: null,
        solution_code: null,
        solution_code_category: defaultSolutionCategory[categoryType],
        option_code_category: defaultOptionCategory[categoryType],
        qty: null,
        price: 0
    };
}

export default priceItem;
