import {
    APPEND_PRICE_ITEM,
    FAILURE_CONFIRM_PAID,
    FAILURE_UPDATE_ESTIMATE,
    INIT_ESTIMATE_FIELDS,
    REMOVE_PRICE_ITEM,
    REQUEST_CONFIRM_PAID,
    REQUEST_UPDATE_ESTIMATE,
    SUCCESS_CONFIRM_PAID,
    SUCCESS_UPDATE_ESTIMATE,
    UPDATE_ESTIMATE_FIELD
} from "../actionTypes";

export const initialState = {
    isLoading: false,
    isLoaded: false,
    form: {
        statuscode: "",
        hashedid: "",
        estid: "",
        reqid: "",
        desc: "",
        period: "",
        deductibleItem: {
            price: 0,
            estimationtype: "01",
            estimationtype_code_category: "01",
            price_component_type: "02",
            price_component_type_category: "02"
        },
        priceItems: {
            cash: [],
            insurance: []
        },
        ad_est_words: "",
        ad_brandid: null,
        period_unit: "일"
    },
    error: null
};

function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case INIT_ESTIMATE_FIELDS: {
            return {
                ...state,
                form: payload
            };
        }

        case UPDATE_ESTIMATE_FIELD: {
            return {
                ...state,
                form: {
                    ...payload
                }
            };
        }

        case REQUEST_UPDATE_ESTIMATE:
        case REQUEST_CONFIRM_PAID: {
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                error: null
            };
        }

        case FAILURE_UPDATE_ESTIMATE:
        case FAILURE_CONFIRM_PAID: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: payload
            };
        }

        case SUCCESS_UPDATE_ESTIMATE:
        case SUCCESS_CONFIRM_PAID: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: null
            };
        }

        case APPEND_PRICE_ITEM: {
            return {
                ...state,
                form: {
                    ...state.form,
                    priceItems: {
                        ...state.form.priceItems,
                        [payload.pType]: [...state.form.priceItems[payload.pType], payload.item]
                    }
                }
            };
        }

        case REMOVE_PRICE_ITEM: {
            const { priceItems } = state.form;

            return {
                ...state,
                form: {
                    ...state.form,
                    priceItems: {
                        ...state.form.priceItems,
                        [payload.pType]: priceItems[payload.pType]?.filter(({ shortId }) => shortId !== payload.shortId)
                    }
                }
            };
        }

        default: {
            return state;
        }
    }
}

export default reducer;
