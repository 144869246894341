export const asyncPrefix = {
    r: "REQUEST/",
    f: "FAILURE/",
    s: "SUCCESS/"
};

export const REQUEST_UPLOAD_IMAGES = `${asyncPrefix.r}UPLOAD_IMAGES`;
export const FAILURE_UPLOAD_IMAGES = `${asyncPrefix.f}UPLOAD_IMAGES`;
export const SUCCESS_UPLOAD_IMAGES = `${asyncPrefix.s}UPLOAD_IMAGES`;

// form login
export const UPDATE_LOGIN_FIELD = "FORM-LOGIN/UPDATE";
export const REQUEST_LOGIN = `${asyncPrefix.r}LOGIN`;
export const FAILURE_LOGIN = `${asyncPrefix.f}LOGIN`;
export const SUCCESS_LOGIN = `${asyncPrefix.s}LOGIN`;
export const REQUEST_LOGOUT = `${asyncPrefix.r}LOGOUT`;
export const FAILURE_LOGOUT = `${asyncPrefix.f}LOGOUT`;
export const SUCCESS_LOGOUT = `${asyncPrefix.s}LOGOUT`;

// form-filters
export const UPDATE_FILTER = "FORM-FILTERS/UPDATE";
export const RESET_FILTERS = "FORM-FILTERS/RESET";

// form booking
export const INIT_BOOKING_INFO_FIELDS = "FORM-BOOKING/INIT";
export const UPDATE_BOOKING_INFO_FIELD = "FORM-BOOKING/UPDATE";
export const REQUEST_CANCEL_BOOKING = `${asyncPrefix.r}CANCEL_BOOKING`;
export const FAILURE_CANCEL_BOOKING = `${asyncPrefix.f}CANCEL_BOOKING`;
export const SUCCESS_CANCEL_BOOKING = `${asyncPrefix.s}CANCEL_BOOKING`;
export const REQUEST_UPDATE_BOOKING = `${asyncPrefix.r}UPDATE_BOOKING`;
export const FAILURE_UPDATE_BOOKING = `${asyncPrefix.f}UPDATE_BOOKING`;
export const SUCCESS_UPDATE_BOOKING = `${asyncPrefix.s}UPDATE_BOOKING`;
export const REQUEST_CONFIRM_BOOKING = `${asyncPrefix.r}CONFIRM_BOOKING`;
export const FAILURE_CONFIRM_BOOKING = `${asyncPrefix.f}CONFIRM_BOOKING`;
export const SUCCESS_CONFIRM_BOOKING = `${asyncPrefix.s}CONFIRM_BOOKING`;

// form category
export const REQUEST_FETCH_CATEGORY_05 = `${asyncPrefix.r}FETCH_CATEGORY_05`;
export const FAILURE_FETCH_CATEGORY_05 = `${asyncPrefix.f}FETCH_CATEGORY_05`;
export const SUCCESS_FETCH_CATEGORY_05 = `${asyncPrefix.s}FETCH_CATEGORY_05`;
export const REQUEST_FETCH_CATEGORY_06 = `${asyncPrefix.r}FETCH_CATEGORY_06`;
export const FAILURE_FETCH_CATEGORY_06 = `${asyncPrefix.f}FETCH_CATEGORY_06`;
export const SUCCESS_FETCH_CATEGORY_06 = `${asyncPrefix.s}FETCH_CATEGORY_06`;
export const REQUEST_FETCH_CATEGORY_07 = `${asyncPrefix.r}FETCH_CATEGORY_07`;
export const FAILURE_FETCH_CATEGORY_07 = `${asyncPrefix.f}FETCH_CATEGORY_07`;
export const SUCCESS_FETCH_CATEGORY_07 = `${asyncPrefix.s}FETCH_CATEGORY_07`;

// form estimate
export const INIT_ESTIMATE_FIELDS = "FORM-ESTIMATE/INIT";
export const UPDATE_ESTIMATE_FIELD = "FORM-ESTIMATE/UPDATE";
export const APPEND_PRICE_ITEM = "FORM-ESTIMATE/APPEND_PRICE_ITEM";
export const REMOVE_PRICE_ITEM = "FORM-ESTIMATE/REMOVE_PRICE_ITEM";
export const REQUEST_UPDATE_ESTIMATE = `${asyncPrefix.r}UPDATE_ESTIMATE`;
export const FAILURE_UPDATE_ESTIMATE = `${asyncPrefix.f}UPDATE_ESTIMATE`;
export const SUCCESS_UPDATE_ESTIMATE = `${asyncPrefix.s}UPDATE_ESTIMATE`;
// TODO into form
export const REQUEST_FETCH_INSTANT_ADS_INFO = `${asyncPrefix.r}FETCH_INSTANT_ADS_INFO`;
export const FAILURE_FETCH_INSTANT_ADS_INFO = `${asyncPrefix.f}FETCH_INSTANT_ADS_INFO`;
export const SUCCESS_FETCH_INSTANT_ADS_INFO = `${asyncPrefix.s}FETCH_INSTANT_ADS_INFO`;

// form-invoice
export const INIT_INVOICE = "FORM-INVOICE/INIT";
export const UPDATE_INVOICE_FIELD = "FORM-INVOICE/UPDATE";
export const REQUEST_SUBMIT_INVOICE = `${asyncPrefix.r}SUBMIT_INVOICE`;
export const FAILURE_SUBMIT_INVOICE = `${asyncPrefix.f}SUBMIT_INVOICE`;
export const SUCCESS_SUBMIT_INVOICE = `${asyncPrefix.s}SUBMIT_INVOICE`;

// form-confirmation
export const UPDATE_CONFIRMATION_FIELD = "FORM-CONFIRMATION/UPDATE";
export const REQUEST_SUBMIT_CONFIRMATION = `${asyncPrefix.r}SUBMIT_CONFIRMATION`;
export const FAILURE_SUBMIT_CONFIRMATION = `${asyncPrefix.f}SUBMIT_CONFIRMATION`;
export const SUCCESS_SUBMIT_CONFIRMATION = `${asyncPrefix.s}SUBMIT_CONFIRMATION`;
export const REQUEST_CONFIRM_PAID = `${asyncPrefix.r}CONFIRM_PAID`;
export const FAILURE_CONFIRM_PAID = `${asyncPrefix.f}CONFIRM_PAID`;
export const SUCCESS_CONFIRM_PAID = `${asyncPrefix.s}CONFIRM_PAID`;

// chat
export const REQUEST_FETCH_CHAT_MESSAGES = `${asyncPrefix.r}FETCH_CHAT_MESSAGES`;
export const FAILURE_FETCH_CHAT_MESSAGES = `${asyncPrefix.f}FETCH_CHAT_MESSAGES`;
export const SUCCESS_FETCH_CHAT_MESSAGES = `${asyncPrefix.s}FETCH_CHAT_MESSAGES`;
export const REQUEST_SUBMIT_CHAT_MESSAGE = `${asyncPrefix.r}SUBMIT_CHAT_MESSAGE`;
export const FAILURE_SUBMIT_CHAT_MESSAGE = `${asyncPrefix.f}SUBMIT_CHAT_MESSAGE`;
export const SUCCESS_SUBMIT_CHAT_MESSAGE = `${asyncPrefix.s}SUBMIT_CHAT_MESSAGE`;
export const REQUEST_SUBMIT_CHAT_IMAGE = `${asyncPrefix.r}SUBMIT_CHAT_IMAGE`;
export const FAILURE_SUBMIT_CHAT_IMAGE = `${asyncPrefix.f}SUBMIT_CHAT_IMAGE`;
export const SUCCESS_SUBMIT_CHAT_IMAGE = `${asyncPrefix.s}SUBMIT_CHAT_IMAGE`;

// estimates
export const REQUEST_FETCH_ESTIMATIONS = `${asyncPrefix.r}FETCH_ESTIMATIONS`;
export const FAILURE_FETCH_ESTIMATIONS = `${asyncPrefix.f}FETCH_ESTIMATIONS`;
export const SUCCESS_FETCH_ESTIMATIONS = `${asyncPrefix.s}FETCH_ESTIMATIONS`;
export const REQUEST_FETCH_ESTIMATIONS_COUNT = `${asyncPrefix.r}FETCH_ESTIMATIONS_COUNT`;
export const FAILURE_FETCH_ESTIMATIONS_COUNT = `${asyncPrefix.f}FETCH_ESTIMATIONS_COUNT`;
export const SUCCESS_FETCH_ESTIMATIONS_COUNT = `${asyncPrefix.s}FETCH_ESTIMATIONS_COUNT`;

// estimate
export const REQUEST_FETCH_ESTIMATION = `${asyncPrefix.r}FETCH_ESTIMATION`;
export const FAILURE_FETCH_ESTIMATION = `${asyncPrefix.f}FETCH_ESTIMATION`;
export const SUCCESS_FETCH_ESTIMATION = `${asyncPrefix.s}FETCH_ESTIMATION`;

// estimation often text description
export const REQUEST_FETCH_OFTEN_TEXT_ESTIMATION = `${asyncPrefix.r}FETCH_OFTEN_TEXT_ESTIMATION`;
export const OPEN_OFTEN_TEXTS_ESTIMATION = "OPEN_OFTEN_TEXTS_ESTIMATION";
export const OPEN_OFTEN_TEXT_DETAIL_ESTIMATION = "OPEN_OFTEN_TEXT_DETAIL_ESTIMATION";
export const OPEN_CREATE_OFTEN_TEXT_ESTIMATION = "OPEN_CREATE_OFTEN_TEXT_ESTIMATION";

// terms
export const REQUEST_FETCH_TERMS = `${asyncPrefix.r}FETCH_TERMS`;
export const FAILURE_FETCH_TERMS = `${asyncPrefix.f}FETCH_TERMS`;
export const SUCCESS_FETCH_TERMS = `${asyncPrefix.s}FETCH_TERMS`;

// repair shops
export const SET_SHOP_BY_ADMIN = `ADMIN/SET_SHOP`;
export const REQUEST_FETCH_REPAIR_SHOPS = `${asyncPrefix.r}FETCH_REPAIR_SHOPS`;
export const FAILURE_FETCH_REPAIR_SHOPS = `${asyncPrefix.f}FETCH_REPAIR_SHOPS`;
export const SUCCESS_FETCH_REPAIR_SHOPS = `${asyncPrefix.s}FETCH_REPAIR_SHOPS`;

// auth, shop info
export const REQUEST_CHECK_ADMIN_USER = `${asyncPrefix.r}CHECK_ADMIN_USER`;
export const FAILURE_CHECK_ADMIN_USER = `${asyncPrefix.f}CHECK_ADMIN_USER`;
export const SUCCESS_CHECK_ADMIN_USER = `${asyncPrefix.s}CHECK_ADMIN_USER`;
export const REQUEST_VERIFY_AUTH = `${asyncPrefix.r}VERIFY_AUTH`;
export const FAILURE_VERIFY_AUTH = `${asyncPrefix.f}VERIFY_AUTH`;
export const SUCCESS_VERIFY_AUTH = `${asyncPrefix.s}VERIFY_AUTH`;

// notification
export const REQUEST_FETCH_NOTIFICATION = `${asyncPrefix.r}FETCH_NOTIFICATION`;
export const FAILURE_FETCH_NOTIFICATION = `${asyncPrefix.f}FETCH_NOTIFICATION`;
export const SUCCESS_FETCH_NOTIFICATION = `${asyncPrefix.s}FETCH_NOTIFICATION`;

// unread notification count
export const REQUEST_GET_UNREAD_NOTI_COUNT = `${asyncPrefix.r}GET_UNREAD_NOTI_COUNT`;
export const FAILURE_GET_UNREAD_NOTI_COUNT = `${asyncPrefix.f}GET_UNREAD_NOTI_COUNT`;
export const SUCCESS_GET_UNREAD_NOTI_COUNT = `${asyncPrefix.s}GET_UNREAD_NOTI_COUNT`;

// review
export const REQUEST_FETCH_REVIEWS = `${asyncPrefix.r}FETCH_REVIEWS`;
export const FAILURE_FETCH_REVIEWS = `${asyncPrefix.f}FETCH_REVIEWS`;
export const SUCCESS_FETCH_REVIEWS = `${asyncPrefix.s}FETCH_REVIEWS`;
export const REQUEST_FETCH_REVIEWS_COUNT = `${asyncPrefix.r}FETCH_REVIEWS_COUNT`;
export const FAILURE_FETCH_REVIEWS_COUNT = `${asyncPrefix.f}FETCH_REVIEWS_COUNT`;
export const SUCCESS_FETCH_REVIEWS_COUNT = `${asyncPrefix.s}FETCH_REVIEWS_COUNT`;

// cash
export const REQUEST_GET_CARDOC_CASH = `${asyncPrefix.r}GET_CARDOC_CASH`;
export const FAILURE_GET_CARDOC_CASH = `${asyncPrefix.f}GET_CARDOC_CASH`;
export const SUCCESS_GET_CARDOC_CASH = `${asyncPrefix.s}GET_CARDOC_CASH`;

// my info
export const REQUEST_GET_MY_INFO = `${asyncPrefix.r}GET_MY_INFO`;
export const FAILURE_GET_MY_INFO = `${asyncPrefix.f}GET_MY_INFO`;
export const SUCCESS_GET_MY_INFO = `${asyncPrefix.s}GET_MY_INFO`;

// announcement
export const REQUEST_FETCH_ANNOUNCEMENTS = `${asyncPrefix.r}FETCH_ANNOUNCEMENTS`;
export const FAILURE_FETCH_ANNOUNCEMENTS = `${asyncPrefix.f}FETCH_ANNOUNCEMENTS`;
export const SUCCESS_FETCH_ANNOUNCEMENTS = `${asyncPrefix.s}FETCH_ANNOUNCEMENTS`;

export const REQUEST_FETCH_ANNOUNCEMENT = `${asyncPrefix.r}FETCH_ANNOUNCEMENT`;
export const FAILURE_FETCH_ANNOUNCEMENT = `${asyncPrefix.f}FETCH_ANNOUNCEMENT`;
export const SUCCESS_FETCH_ANNOUNCEMENT = `${asyncPrefix.s}FETCH_ANNOUNCEMENT`;

// unread announcement total count

export const REQUEST_GET_ANNOUNCEMENTS_COUNT = `${asyncPrefix.r}GET_ANNOUNCEMENTS_COUNT`;
export const FAILURE_GET_ANNOUNCEMENTS_COUNT = `${asyncPrefix.f}GET_ANNOUNCEMENTS_COUNT`;
export const SUCCESS_GET_ANNOUNCEMENTS_COUNT = `${asyncPrefix.s}GET_ANNOUNCEMENTS_COUNT`;

// advertisement get location list & advertisement request
export const REQUEST_FETCH_ADVERTISEMENT = `${asyncPrefix.r}FETCH_ADVERTISEMENT`;
export const FAILURE_FETCH_ADVERTISEMENT = `${asyncPrefix.f}FETCH_ADVERTISEMENT`;
export const SUCCESS_FETCH_ADVERTISEMENT = `${asyncPrefix.s}FETCH_ADVERTISEMENT`;

// repair case
export const REQUEST_FETCH_REPAIR_CASES = `${asyncPrefix.r}FETCH_REPAIR_CASES`;
export const FAILURE_FETCH_REPAIR_CASES = `${asyncPrefix.f}FETCH_REPAIR_CASES`;
export const SUCCESS_FETCH_REPAIR_CASES = `${asyncPrefix.s}FETCH_REPAIR_CASES`;

export const REQUEST_FETCH_REPAIR_CASE = `${asyncPrefix.r}FETCH_REPAIR_CASE`;
export const FAILURE_FETCH_REPAIR_CASE = `${asyncPrefix.f}FETCH_REPAIR_CASE`;
export const SUCCESS_FETCH_REPAIR_CASE = `${asyncPrefix.s}FETCH_REPAIR_CASE`;

export const REQUEST_GET_REPAIR_CASE_COUNT = `${asyncPrefix.r}GET_REPAIR_CASE_COUNT`;
export const FAILURE_GET_REPAIR_CASE_COUNT = `${asyncPrefix.f}GET_REPAIR_CASE_COUNT`;
export const SUCCESS_GET_REPAIR_CASE_COUNT = `${asyncPrefix.s}GET_REPAIR_CASE_COUNT`;

// repair case write data
export const REQUEST_FETCH_SHOP_REPAIR_LIST = `${asyncPrefix.r}FETCH_SHOP_REPAIR_LIST`;
export const FAILURE_FETCH_SHOP_REPAIR_LIST = `${asyncPrefix.f}FETCH_SHOP_REPAIR_LIST`;
export const SUCCESS_FETCH_SHOP_REPAIR_LIST = `${asyncPrefix.s}FETCH_SHOP_REPAIR_LIST`;

export const REQUEST_FETCH_REPAIR_PARTS_LIST = `${asyncPrefix.r}FETCH_REPAIR_PARTS_LIST`;
export const FAILURE_FETCH_REPAIR_PARTS_LIST = `${asyncPrefix.f}FETCH_REPAIR_PARTS_LIST`;
export const SUCCESS_FETCH_REPAIR_PARTS_LIST = `${asyncPrefix.s}FETCH_REPAIR_PARTS_LIST`;

export const REQUEST_FETCH_CAR_BRAND_TYPE_LIST = `${asyncPrefix.r}FETCH_CAR_BRAND_TYPE_LIST`;
export const FAILURE_FETCH_CAR_BRAND_TYPE_LIST = `${asyncPrefix.f}FETCH_CAR_BRAND_TYPE_LIST`;
export const SUCCESS_FETCH_CAR_BRAND_TYPE_LIST = `${asyncPrefix.s}FETCH_CAR_BRAND_TYPE_LIST`;

export const INIT_REPAIR_CASE_DATA = "REPAIR_CASE_DATA/INIT";

// regional settings
export const REQUEST_GET_SHOP_ASSIGN_INFO = `${asyncPrefix.r}GET_SHOP_ASSIGN_INFO`;
export const FAILURE_GET_SHOP_ASSIGN_INFO = `${asyncPrefix.f}GET_SHOP_ASSIGN_INFO`;
export const SUCCESS_GET_SHOP_ASSIGN_INFO = `${asyncPrefix.s}GET_SHOP_ASSIGN_INFO`;

// cash
export const REQUEST_GET_CASH_INFO = `${asyncPrefix.r}GET_CASH_INFO`;
export const FAILURE_GET_CASH_INFO = `${asyncPrefix.f}GET_CASH_INFO`;
export const SUCCESS_GET_CASH_INFO = `${asyncPrefix.s}GET_CASH_INFO`;

export const REQUEST_GET_SHOP_CASH_INFO = `${asyncPrefix.r}GET_SHOP_CASH_INFO`;
export const FAILURE_GET_SHOP_CASH_INFO = `${asyncPrefix.f}GET_SHOP_CASH_INFO`;
export const SUCCESS_GET_SHOP_CASH_INFO = `${asyncPrefix.s}GET_SHOP_CASH_INFO`;

export const REQUEST_GET_CASH_CONVERT_LIST = `${asyncPrefix.r}GET_CASH_CONVERT_LIST`;
export const FAILURE_GET_CASH_CONVERT_LIST = `${asyncPrefix.f}GET_CASH_CONVERT_LIST`;
export const SUCCESS_GET_CASH_CONVERT_LIST = `${asyncPrefix.s}GET_CASH_CONVERT_LIST`;

export const REQUEST_GET_CASH_CONVERT_COUNT = `${asyncPrefix.r}GET_CASH_CONVERT_COUNT`;
export const FAILURE_GET_CASH_CONVERT_COUNT = `${asyncPrefix.f}GET_CASH_CONVERT_COUNT`;
export const SUCCESS_GET_CASH_CONVERT_COUNT = `${asyncPrefix.s}GET_CASH_CONVERT_COUNT`;

// cash-history
export const REQUEST_GET_CASH_HISTORY = `${asyncPrefix.r}GET_CASH_HISTORY`;
export const SUCCESS_GET_CASH_HISTORY = `${asyncPrefix.f}GET_CASH_HISTORY`;
export const FAILURE_GET_CASH_HISTORY = `${asyncPrefix.s}GET_CASH_HISTORY`;

export const REQUEST_GET_CASH_HISTORY_COUNT = `${asyncPrefix.r}GET_CASH_HISTORY_COUNT`;
export const SUCCESS_GET_CASH_HISTORY_COUNT = `${asyncPrefix.f}GET_CASH_HISTORY_COUNT`;
export const FAILURE_GET_CASH_HISTORY_COUNT = `${asyncPrefix.s}GET_CASH_HISTORY_COUNT`;
