import { genericError } from "~/packages/utils";
import {
    FAILURE_GET_CASH_CONVERT_COUNT,
    FAILURE_GET_CASH_CONVERT_LIST,
    FAILURE_GET_CASH_INFO,
    FAILURE_GET_SHOP_CASH_INFO,
    REQUEST_GET_CASH_CONVERT_COUNT,
    REQUEST_GET_CASH_CONVERT_LIST,
    REQUEST_GET_CASH_INFO,
    REQUEST_GET_SHOP_CASH_INFO,
    SUCCESS_GET_CASH_CONVERT_COUNT,
    SUCCESS_GET_CASH_CONVERT_LIST,
    SUCCESS_GET_CASH_INFO,
    SUCCESS_GET_SHOP_CASH_INFO
} from "../actionTypes";

// shop 캐시 관련
export function fetchCash() {
    return async (dispatch, getState, api) => {
        const {
            user: { shopid: shopId }
        } = getState();

        try {
            if (!shopId) {
                throw genericError("no shopid");
            }

            dispatch({
                type: REQUEST_GET_CASH_INFO
            });

            if (shopId) {
                const cash = await api.getCashBalance(shopId);

                dispatch({
                    type: SUCCESS_GET_CASH_INFO,
                    payload: cash
                });
            }
        } catch (err) {
            dispatch({
                type: FAILURE_GET_CASH_INFO,
                payload: err
            });
        }
    };
}

// 자동 충전 관련
export function fetchAutoPayment() {
    return async (dispatch, getState, api) => {
        const {
            user: { shopid: shopId }
        } = getState();

        try {
            if (!shopId) {
                throw genericError("no shopid");
            }

            dispatch({
                type: REQUEST_GET_SHOP_CASH_INFO
            });

            if (shopId) {
                const cash = await api.getAutoPayment(shopId);

                dispatch({
                    type: SUCCESS_GET_SHOP_CASH_INFO,
                    payload: cash
                });
            }
        } catch (err) {
            dispatch({
                type: FAILURE_GET_SHOP_CASH_INFO,
                payload: err
            });
        }
    };
}

export function fetchCashConvertedPaymentList(page, pageSize) {
    return async (dispatch, getState, api) => {
        const {
            user: { shopid: shopId }
        } = getState();

        try {
            if (!shopId) {
                throw genericError("no shopid");
            }

            dispatch({
                type: REQUEST_GET_CASH_CONVERT_LIST
            });

            if (shopId) {
                const cash = await api.getCashConvertedPaymentList(shopId, page, pageSize, true);

                dispatch({
                    type: SUCCESS_GET_CASH_CONVERT_LIST,
                    payload: cash
                });
            }
        } catch (err) {
            dispatch({
                type: FAILURE_GET_CASH_CONVERT_LIST,
                payload: err
            });
        }
    };
}

export function fetchCashConvertedPaymentCount() {
    return async (dispatch, getState, api) => {
        const {
            user: { shopid: shopId }
        } = getState();

        try {
            if (!shopId) {
                throw genericError("no shopid");
            }

            dispatch({
                type: REQUEST_GET_CASH_CONVERT_COUNT
            });

            if (shopId) {
                const { count } = await api.getCashConvertedPaymentCount(shopId, true);
                dispatch({
                    type: SUCCESS_GET_CASH_CONVERT_COUNT,
                    payload: Number(count) ?? 0
                });
            }
        } catch (err) {
            dispatch({
                type: FAILURE_GET_CASH_CONVERT_COUNT,
                payload: err
            });
        }
    };
}
