import { DUCKTIBLE } from "../presets";

/**
 * Detect whether Collision Damage Waiver(CDW) registered (insurance)
 * @param  {Object}  insurance
 * @return {Boolean}
 */
function detectCDWRegistered(insurance) {
    const { insuId, collision_coverage: cc } = insurance;

    return insuId && [DUCKTIBLE[20], DUCKTIBLE[30]].includes(cc);
}

export default detectCDWRegistered;
