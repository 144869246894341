import routes from "./routes";
import styled from "@emotion/styled";
import { ThemeProvider } from "evergreen-ui";
import React, { Suspense, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import MobileHeader from "~/components/MobileHeader";
import MenuContainer from "~/containers/MenuContainer";
import useChannelTalk from "~/packages/hooks/useChannelTalk";
import usePageVariables from "~/packages/hooks/usePageVariables";
import Loading from "~/packages/ui/Loading";
import ProtectRoute from "~/routes/ProtectRoute";
import { verifyAuth } from "~/store/actions";
import theme from "~/theme";

function App() {
    const dispatch = useDispatch();
    const { isLoading } = useSelector(state => state.user);
    const { isPublicPage, isResponsivePage, isShowDesktopMenuPage, isShowMobileMenuPage } = usePageVariables();

    useEffect(() => {
        if (!isPublicPage) {
            dispatch(verifyAuth());
        }
    }, [dispatch, isPublicPage]);

    useChannelTalk();

    /**
     * @desc verifyAuth 함수 호출로 인증 확인 후 화면 렌더링
     **/
    if (!isPublicPage && isLoading) {
        return <></>;
    }

    return (
        <ThemeProvider value={theme}>
            <Suspense fallback={<Loading height={"100vh"} />}>
                <PageContainer isResponsive={isResponsivePage}>
                    {isShowMobileMenuPage && <MobileHeader />}
                    {isShowDesktopMenuPage && <MenuContainer />}
                    {isResponsivePage && (
                        <Helmet>
                            <meta name={"viewport"} content={"width=device-width, initial-scale=1"} />
                        </Helmet>
                    )}
                    <Routes>
                        {routes.map(item => {
                            const Component = item.component;
                            return (
                                <Route
                                    key={item.path}
                                    path={item.path}
                                    element={
                                        <ProtectRoute isPrivate={item.isPrivate}>
                                            <Component key={item.path} {...item} />
                                        </ProtectRoute>
                                    }
                                />
                            );
                        })}
                    </Routes>
                </PageContainer>
            </Suspense>
        </ThemeProvider>
    );
}

export default App;

const PageContainer = styled.div`
    /* ${({ isResponsive }) =>
        !isResponsive &&
        `
    max-width: 2000px;
    min-width: 1200px;
  `} */
    height: 100% !important;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    /* border-right: 1px solid #ddd; */
`;
