export const CHECK_ADMIN_USER = "/currentUser";
export const VERIFY_SESSION = "/shop/me";
export const FETCH_SHOP_INFO = VERIFY_SESSION;
export const CREATE_SESSION = "/dtsession";
export const DELETE_SESSION = "/dtsession";
export const VERIFY_TERMS_STATE = "/shopTerm/checkState";
export const FETCH_ESTIMATIONS = "/estimation/shopid/:shopId/statuscode/:statusCode/page/:page";
export const FETCH_ESTIMATION = "/estimation/:estId";
export const FETCH_ESTIMATIONS_COUNT = "/estimation/shopid/:shopId/statuscode/:statusCode/countYn/Y";
export const FETCH_TERMS = "/shopTerm?page=shop_main";
export const FETCH_INSTANT_ADS_INFO = "/ad_est_words/shopid/:shopId";
export const SUBSCRIBE_INSTANT_ADS = "/ad_est_words";
export const FETCH_CATEGORY_05 = "/code/category/05";
export const FETCH_CATEGORY_06 = "/code/category/06";
export const FETCH_CATEGORY_07 = "/code/category/07";
export const UPDATE_ESTIMATE = "/estimation/:estId";
export const FETCH_CHAT_MESSAGES = "/estimationChatMessage/estid/:estId/reqid/:reqId/latestchatid/:latestChatId";
export const SUBMIT_CHAT_MESSAGE = "/estimationChatMessage";
export const SUBMIT_CHAT_IMAGE = SUBMIT_CHAT_MESSAGE;
export const UPLOAD_FILES = "/fileObject";
export const FETCH_SHOPS = "/monitor/shopMap/:service";
export const FETCH_NOTIFICATION = "/bms/notifications/web/shop/:shopId";
export const NOTI_ALL_READ = `/bms/notifications/web/read/shop/:shopId`;
export const GET_UNREAD_NOTI_COUNT = `/bms/notifications/web/unread-count/shop/:shopId`;
export const READ_NOTIFICATION = `/bms/notifications/web/read/:notiId`;
export const FETCH_REVIEW = "/shopReview/shop/:shopId/page/:page";
export const FETCH_REVIEW_COUNT = "/shopReview/shop/:shopId/countYn/Y";
export const FETCH_BALANCE = "/cardocCashBalances/:shopId";
export const FETCH_ANNOUNCEMENTS = "/shopAnnouncement/page/:page";
export const FETCH_ANNOUNCEMENT = "/shopAnnouncement/:annId";
export const GET_UNREAD_ANN_COUNT = "/shopAnnouncement/countyn/Y/unreadyn/Y";
export const GET_ANNOUNCEMENTS_COUNT = "/shopAnnouncement/countyn/Y";
export const FETCH_ADVERTISEMENT = "/ad_shop_list/:shopId";
export const SUBMIT_ADVERTISEMENT = "/ad_shop_list/:shopId";
export const SUBMIT_REPAIR_CASE = "/afterGallery";
export const FETCH_REPAIR_CASES = "/afterGallery/shopid/:shopId/page/:page";
export const FETCH_REPAIR_CASE = `/afterGallery/:caseId`;
export const DELETE_REPAIR_CASE = `/afterGallery/:caseId`;
export const UPDATE_REPAIR_CASE = `/afterGallery/:caseId`;
export const GET_REPAIR_CASE_COUNT = "/afterGallery/shopid/:shopId/countYn/Y";
export const FETCH_SHOP_REPAIR_LIST = "/estimation/shopid/:shopId/statuscode/800/page/0/gallerymapYn/N/estid/:estId";
export const FETCH_REPAIR_PARTS_LIST = "/code/category/11";
export const FETCH_CAR_BRAND_TYPE_LIST = "/brand";
export const SUBMIT_ONELINE_AD_TEXT = "/ad_est_words";
