import { Autocomplete, Button, Heading, IconButton, LogOutIcon, Pane, Pill, Position, SearchInput } from "evergreen-ui";
import PropTypes from "prop-types";
import qs from "querystring";
import { compose, isNil, nth, split } from "ramda";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CardocLogoSvg from "~/assets/cardoc-logo.svg";
import YoutubeIcon from "~/assets/img/youtube-icon.png";
import { useAmplitude } from "~/packages/bootstrap";
import Flex from "~/packages/ui/Flex";
import FlexCol from "~/packages/ui/FlexCol";
import FlexRow from "~/packages/ui/FlexRow";
import { cleanPath, openInNewTab } from "~/packages/utils";
import { SHOPADMIN_AMPLITUDE } from "~/presets";
import {
    ANNOUNCEMENT,
    ANN_DETAIL,
    CASH,
    CASH_CARD_REGISTRATION,
    CASH_HISTORY,
    CASH_RECHARGE,
    LIST,
    MY_INFO,
    REGIONAL_SETTINGS,
    REPAIR_CASE,
    REPAIR_CASE_WRITE
} from "~/routes";
import ChannelService from "~/shared/ChannelService";
import { Colors } from "~/theme/colors";
import css from "./menu.css";

function Menu({
    shops,
    isAdmin,
    menu,
    loginid,
    shopid,
    shopname,
    businessType,
    logout,
    setShopByAdmin,
    unreadNotiCount,
    cardocCash,
    getUnreadNotiCount,
    getBalance
}) {
    const amplitude = useAmplitude();
    const [btnRef, setBtnRef] = useState();
    const [currentPath, setCurrentPath] = useState(LIST);
    const navigate = useNavigate();
    const { search, pathname } = useLocation();
    const { shopid: qsShopId, estid: qsEstId } = qs.parse(search.substr(1));

    const foundShop = useMemo(() => {
        return shops.find(item => item.shopid === qsShopId);
    }, [shops, qsShopId]);

    const convertMenuPath = useCallback(_path => {
        switch (_path) {
            case ANNOUNCEMENT:
            case ANN_DETAIL:
                return ANNOUNCEMENT;
            case REPAIR_CASE:
            case REPAIR_CASE_WRITE:
                return REPAIR_CASE;
            case CASH:
            case CASH_HISTORY:
            case CASH_RECHARGE:
            case CASH_CARD_REGISTRATION:
                return CASH;
            default:
                return _path;
        }
    }, []);

    const selectMenu = useCallback(
        (_key, _to) => {
            if (_key === "counseling") {
                ChannelService.show();
            }
            const path = _to.split("/");
            const currentPath = convertMenuPath(`/${path[1]}`);
            setCurrentPath(convertMenuPath(currentPath));
        },
        [convertMenuPath]
    );

    useEffect(() => {
        const isDeepLink = btnRef && foundShop;

        if (isAdmin && isDeepLink) {
            Promise.resolve()
                .then(() => btnRef.click())
                .then(() => navigate(cleanPath([LIST, 1, qsEstId])));
        }
    }, [foundShop, btnRef, qsEstId, isAdmin, navigate]);

    useEffect(() => {
        if (shopid) {
            getUnreadNotiCount(shopid);
            getBalance(shopid);
        }
    }, [shopid, getUnreadNotiCount, getBalance]);

    useEffect(() => {
        const currentPath = convertMenuPath(`/${pathname.split("/")[1]}`);
        setCurrentPath(convertMenuPath(currentPath));
    }, [pathname, convertMenuPath]);

    const autocompleteProps = {
        items: shops,
        itemToString: item => {
            if (!item) {
                return "";
            }

            return `[${item.shopid}] ${item.shopname}`;
        }
    };

    return (
        <FlexCol>
            <FlexRow alignItems={"center"} height={40} paddingX={20} background={"orangeTint"} borderBottom={"muted"}>
                {isAdmin && (
                    <FlexRow alignItems={"center"} marginRight={30}>
                        <Pane>
                            <Heading size={200} marginRight={5}>
                                관리자 모드
                            </Heading>
                        </Pane>

                        <Pane>
                            <Autocomplete
                                {...(isAdmin
                                    ? {
                                          ...autocompleteProps,
                                          selectedItem: foundShop
                                      }
                                    : autocompleteProps)}
                            >
                                {({ key, getInputProps, getRef, inputValue, openMenu }) => {
                                    return (
                                        <Pane key={key} ref={getRef}>
                                            <SearchInput
                                                position={Position.BOTTOM}
                                                placeholder={"업체 검색 (아이디, 이름)"}
                                                width={200}
                                                value={inputValue}
                                                onFocus={openMenu}
                                                marginRight={5}
                                                {...getInputProps()}
                                            />
                                            <Button
                                                ref={setBtnRef}
                                                onClick={() => {
                                                    if (inputValue) {
                                                        const selectedShopId = compose(
                                                            v => v.substr(1),
                                                            nth(0),
                                                            split("]")
                                                        )(inputValue);

                                                        const selectedShop = shops.find(item => {
                                                            return item.shopid === selectedShopId;
                                                        });

                                                        const shopIdPath = selectedShop?.shopid;
                                                        const currentURL = `/${pathname.split("/")[1]}`;
                                                        switch (currentURL) {
                                                            case REGIONAL_SETTINGS:
                                                            case REPAIR_CASE:
                                                            case MY_INFO:
                                                            case CASH:
                                                            case CASH_HISTORY:
                                                                navigate(cleanPath([currentURL, shopIdPath]));
                                                                break;
                                                            case REPAIR_CASE_WRITE:
                                                                navigate(cleanPath([REPAIR_CASE, shopIdPath]));
                                                                break;
                                                            default:
                                                                break;
                                                        }

                                                        setShopByAdmin(selectedShop);
                                                    }
                                                }}
                                            >
                                                선택
                                            </Button>
                                        </Pane>
                                    );
                                }}
                            </Autocomplete>
                        </Pane>
                    </FlexRow>
                )}

                <FlexRow marginRight={10}>
                    <Heading size={200} marginRight={5}>
                        로그인 아이디:
                    </Heading>
                    <Heading size={300}>{loginid}</Heading>
                </FlexRow>

                {shopid && (
                    <FlexRow marginRight={10}>
                        <Heading size={200} marginRight={5}>
                            업체 아이디:
                        </Heading>
                        <Heading size={300}>{shopid}</Heading>
                    </FlexRow>
                )}

                {shopname && (
                    <FlexRow marginRight={10}>
                        <Heading size={200} marginRight={5}>
                            업체 이름:
                        </Heading>
                        <Heading size={300}>{shopname}</Heading>
                    </FlexRow>
                )}

                {businessType && (
                    <FlexRow marginRight={10}>
                        <Heading size={200} marginRight={5}>
                            업종:
                        </Heading>
                        <Heading size={300}>{businessType}</Heading>
                    </FlexRow>
                )}
            </FlexRow>

            <FlexRow borderBottom={"default"} background={"tint2"} alignItems={"center"} height={70}>
                <Flex justifyContent={"center"} borderRight={"default"} flexBasis={200}>
                    <CardocLogoSvg />
                </Flex>

                <FlexRow flex={1}>
                    {Object.keys(menu).map(key => {
                        const { name, to = "/" } = menu[key];
                        const path = `/${to.split("/")[1]}`;
                        return (
                            <Flex
                                key={key}
                                alignItems={"center"}
                                borderRight={"default"}
                                paddingRight={20}
                                paddingLeft={20}
                                className={css.menuheader}
                            >
                                <Link
                                    to={key === "counseling" ? "#" : to}
                                    style={{ width: "100%" }}
                                    onClick={() => selectMenu(key, to)}
                                >
                                    <Heading
                                        size={400}
                                        textAlign={"center"}
                                        fontWeight={path === currentPath ? "bold" : "normal"}
                                        color={path === currentPath ? Colors.blue_100 : Colors.black}
                                        className={css.menuheader}
                                    >
                                        {name}
                                        {key === "noti" && (
                                            <Pill marginLeft={5} color={"red"} isSolid>
                                                {unreadNotiCount > 100 ? "+100" : unreadNotiCount}
                                            </Pill>
                                        )}
                                        {key === "cash" && !isNil(cardocCash) && (
                                            <Pill marginLeft={5} color={"red"} isSolid>
                                                {cardocCash?.toLocaleString("ko-KR") ?? ""}
                                            </Pill>
                                        )}
                                    </Heading>
                                </Link>
                            </Flex>
                        );
                    })}
                </FlexRow>
                <FlexRow marginRight={10}>
                    <Button
                        borderRadius={30}
                        background={"white"}
                        onClick={() =>
                            openInNewTab("https://www.youtube.com/playlist?list=PLrsvOhYDhPq4Y6QafVhw4-B7nCeUg3U9L")
                        }
                    >
                        <img width={20} style={{ marginRight: "4px" }} src={YoutubeIcon} alt={"youtube icon"} />앱
                        사용법 보기
                    </Button>
                </FlexRow>
                <Pane flexBasis={50}>
                    <IconButton
                        icon={LogOutIcon}
                        appearance={"minimal"}
                        onClick={() => {
                            amplitude.logEvent(SHOPADMIN_AMPLITUDE.signOut);
                            ChannelService.shutdown();
                            logout();
                        }}
                    />
                </Pane>
            </FlexRow>
        </FlexCol>
    );
}

Menu.propTypes = {
    menu: PropTypes.shape({}).isRequired,
    logout: PropTypes.func.isRequired,
    setShopByAdmin: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool,
    loginid: PropTypes.string,
    shopid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    shopname: PropTypes.string,
    shops: PropTypes.arrayOf(PropTypes.shape({})),
    type: PropTypes.string,
    businessType: PropTypes.string,
    cardocCash: PropTypes.number.isRequired,
    unreadNotiCount: PropTypes.number.isRequired,
    getUnreadNotiCount: PropTypes.func.isRequired,
    getBalance: PropTypes.func.isRequired
};

Menu.defaultProps = {
    isAdmin: false,
    loginid: "",
    shopid: "",
    shopname: "",
    shops: [],
    type: "",
    businessType: ""
};

export default Menu;
