import {
    FAILURE_FETCH_ANNOUNCEMENT,
    FAILURE_FETCH_ANNOUNCEMENTS,
    FAILURE_GET_ANNOUNCEMENTS_COUNT,
    REQUEST_FETCH_ANNOUNCEMENT,
    REQUEST_FETCH_ANNOUNCEMENTS,
    REQUEST_GET_ANNOUNCEMENTS_COUNT,
    SUCCESS_FETCH_ANNOUNCEMENT,
    SUCCESS_FETCH_ANNOUNCEMENTS,
    SUCCESS_GET_ANNOUNCEMENTS_COUNT
} from "../actionTypes";

export function fetchAnnouncements(page) {
    return async (dispatch, getState, api) => {
        try {
            dispatch({
                type: REQUEST_FETCH_ANNOUNCEMENTS
            });

            const announcements = await api.fetchAnnouncements({
                page
            });

            dispatch({
                type: SUCCESS_FETCH_ANNOUNCEMENTS,
                payload: announcements
            });
        } catch (err) {
            dispatch({
                type: FAILURE_FETCH_ANNOUNCEMENTS,
                payload: err
            });
        }
    };
}

export function fetchAnnouncement(annId) {
    return async (dispatch, getState, api) => {
        try {
            dispatch({
                type: REQUEST_FETCH_ANNOUNCEMENT
            });

            const announcement = await api.fetchAnnouncement({
                annId
            });

            dispatch({
                type: SUCCESS_FETCH_ANNOUNCEMENT,
                payload: announcement
            });
        } catch (err) {
            dispatch({
                type: FAILURE_FETCH_ANNOUNCEMENT,
                payload: err
            });
        }
    };
}

export function getAnnouncementsCount() {
    return async (dispatch, getState, api) => {
        try {
            dispatch({
                type: REQUEST_GET_ANNOUNCEMENTS_COUNT
            });

            const annCount = await api.getAnnouncementsCount();

            dispatch({
                type: SUCCESS_GET_ANNOUNCEMENTS_COUNT,
                payload: annCount
            });
        } catch (err) {
            dispatch({
                type: FAILURE_GET_ANNOUNCEMENTS_COUNT,
                payload: err
            });
        }
    };
}
