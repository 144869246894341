import { ESTIMATE_STATUS_MAP } from "../presets";

/**
 * Detect whether current shop status code would be able to proceed or not
 * @param  {Number}  statusCode
 * @param  {Number}  maxStatusCode
 * @return {Boolean}
 */
function detectBookedByAnotherShop({ statusCode, maxStatusCode }) {
    return maxStatusCode >= ESTIMATE_STATUS_MAP["예약확정"] && statusCode < maxStatusCode;
}

export default detectBookedByAnotherShop;
