import React, { memo } from "react";
import PropTypes from "prop-types";
import Flex from "./Flex";

function FlexCol({ children, ...props }) {
    return (
        <Flex flexDirection={"column"} {...props}>
            {children}
        </Flex>
    );
}

FlexCol.propTypes = {
    children: PropTypes.node.isRequired
};

export default memo(FlexCol);
