import {
    FAILURE_FETCH_ESTIMATIONS,
    FAILURE_FETCH_ESTIMATIONS_COUNT,
    REQUEST_FETCH_ESTIMATIONS,
    REQUEST_FETCH_ESTIMATIONS_COUNT,
    SUCCESS_FETCH_ESTIMATIONS,
    SUCCESS_FETCH_ESTIMATIONS_COUNT
} from "../actionTypes";

const initialState = {
    isLoading: false,
    isLoaded: false,
    count: 0,
    list: [],
    error: null
};

function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case REQUEST_FETCH_ESTIMATIONS: {
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                list: [],
                error: null
            };
        }

        case FAILURE_FETCH_ESTIMATIONS: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                list: [],
                error: payload
            };
        }

        case SUCCESS_FETCH_ESTIMATIONS: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                list: payload,
                error: null
            };
        }

        case REQUEST_FETCH_ESTIMATIONS_COUNT: {
            return {
                ...state,
                isLoading: true,
                pageSize: 0,
                count: 0,
                error: null
            };
        }

        case FAILURE_FETCH_ESTIMATIONS_COUNT: {
            return {
                ...state,
                isLoading: false,
                pageSize: 0,
                count: 0,
                error: payload
            };
        }

        case SUCCESS_FETCH_ESTIMATIONS_COUNT: {
            return {
                ...state,
                isLoading: false,
                pageSize: payload.pageSize,
                count: payload.total,
                error: null
            };
        }

        default: {
            return state;
        }
    }
}

export default reducer;
