import {
    FAILURE_FETCH_ANNOUNCEMENT,
    FAILURE_FETCH_ANNOUNCEMENTS,
    FAILURE_GET_ANNOUNCEMENTS_COUNT,
    REQUEST_FETCH_ANNOUNCEMENT,
    REQUEST_FETCH_ANNOUNCEMENTS,
    REQUEST_GET_ANNOUNCEMENTS_COUNT,
    SUCCESS_FETCH_ANNOUNCEMENT,
    SUCCESS_FETCH_ANNOUNCEMENTS,
    SUCCESS_GET_ANNOUNCEMENTS_COUNT
} from "../actionTypes";

const initialState = {
    isLoading: false,
    isLoaded: false,
    annList: [],
    data: {},
    annTotalCount: 0,
    error: null
};

function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case REQUEST_FETCH_ANNOUNCEMENTS: {
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                error: null
            };
        }

        case FAILURE_FETCH_ANNOUNCEMENTS: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                annList: [],
                error: payload
            };
        }

        case SUCCESS_FETCH_ANNOUNCEMENTS: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                annList: payload,
                error: null
            };
        }

        case REQUEST_FETCH_ANNOUNCEMENT: {
            return {
                ...state,
                isLoading: true,
                data: {},
                error: null
            };
        }

        case FAILURE_FETCH_ANNOUNCEMENT: {
            return {
                ...state,
                isLoading: false,
                data: {},
                error: payload
            };
        }

        case SUCCESS_FETCH_ANNOUNCEMENT: {
            return {
                ...state,
                isLoading: false,
                data: payload,
                error: null
            };
        }

        case REQUEST_GET_ANNOUNCEMENTS_COUNT: {
            return {
                ...state,
                error: null
            };
        }

        case FAILURE_GET_ANNOUNCEMENTS_COUNT: {
            return {
                ...state,
                annTotalCount: 0,
                error: payload.error
            };
        }

        case SUCCESS_GET_ANNOUNCEMENTS_COUNT: {
            return {
                ...state,
                annTotalCount: payload,
                error: null
            };
        }

        default: {
            return state;
        }
    }
}

export default reducer;
