import {
    REQUEST_SUBMIT_INVOICE,
    FAILURE_SUBMIT_INVOICE,
    SUCCESS_SUBMIT_INVOICE,
    INIT_INVOICE,
    UPDATE_INVOICE_FIELD
} from "../actionTypes";

const initialState = {
    isLoading: false,
    isLoaded: false,
    form: {
        statuscode: "550",
        hashedid: "",
        estid: "",
        reqid: "",
        statement: {
            estimationtype: "",
            release_date: ""
        }
    },
    error: null
};

function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case INIT_INVOICE: {
            return {
                ...state,
                form: {
                    ...state.form,
                    ...payload
                }
            };
        }

        case UPDATE_INVOICE_FIELD: {
            return {
                ...state,
                form: {
                    ...state.form,
                    statement: {
                        ...state.form.statement,
                        [payload.name]: payload.value
                    }
                }
            };
        }

        case REQUEST_SUBMIT_INVOICE: {
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                error: null
            };
        }

        case FAILURE_SUBMIT_INVOICE: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: payload
            };
        }

        case SUCCESS_SUBMIT_INVOICE: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: null
            };
        }

        default: {
            return state;
        }
    }
}

export default reducer;
