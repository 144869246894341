import { lazy } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { clean } from "~/packages/utils";

export const HOME = "/";
export const LOGIN = "/login";
export const PASSWORD_RESET = "/password-reset";
export const LIST = "/list";
export const NOTI = "/noti";
export const REVIEW = "/review";
export const REGIONAL_SETTINGS = "/regional-settings";
export const MY_INFO = "/my-info";
export const ANNOUNCEMENT = "/announcement";
export const ANN_DETAIL = "/announcement-detail";
export const AD = "/ad";
export const REPAIR_CASE = "/repair-case";
export const REPAIR_CASE_WRITE = "/repair-case-write";
export const CASH = "/cash";
export const CASH_HISTORY = "/cash-history";
export const CASH_RECHARGE = "/cash-recharge";
export const CASH_CARD_REGISTRATION = "/cash-card-registration";

function Home() {
    const { estId } = useParams();
    const path = clean([LIST, 1, estId]).join("/");

    return <Navigate to={path} replace />;
}

function NoMatch() {
    return (
        <div>
            <Link to={HOME}>길을 잃어버리셨군요. 초기 화면으로 돌아가기!</Link>
        </div>
    );
}

/** 
 * @author 이종민
 * @description
/* router param을 넣고 해당 값을 redux shopId 에 안 넣는 이유는
/* 휴점 및 등록이 방금된 사업장은 shops 리스트 안에 없는 경우가 있어서 path params의 값을 그대로 shopId 로 넣어준다.
*/
export const routes = [
    {
        path: "*",
        component: NoMatch,
        isPrivate: false
    },
    {
        path: LOGIN,
        component: lazy(() => import("~/pages/LoginPage")),
        isPrivate: false
    },
    {
        path: HOME,
        component: Home,
        isPrivate: false
    },
    {
        path: PASSWORD_RESET,
        component: lazy(() => import("~/pages/PasswordResetPage")),
        isPrivate: false
    },
    {
        path: `${LIST}/:pageNo?/:estId?/*`,
        component: lazy(() => import("~/pages/EstimationsPage")),
        isPrivate: true
    },
    {
        path: `${NOTI}/:pageNo?/*`,
        component: lazy(() => import("~/pages/NotificationPage")),
        isPrivate: true
    },
    // 캐시관리
    {
        path: `${CASH}/:shopId/*`,
        component: lazy(() => import("~/pages/CashPage")),
        isPrivate: true
    },
    // 캐시관리 - 사용 내역 상세보기
    {
        path: `${CASH_HISTORY}/:shopId/*`,
        component: lazy(() => import("~/pages/CashHistoryPage")),
        isPrivate: true
    },
    // 캐시관리 - 충전하기
    {
        path: `${CASH_RECHARGE}/*`,
        component: lazy(() => import("~/pages/CashRechargePage")),
        isPrivate: true
    },
    // 캐시관리 - 자동충전 등록
    {
        path: `${CASH_CARD_REGISTRATION}/*`,
        component: lazy(() => import("~/pages/CashCardRegistrationPage")),
        isPrivate: true
    },
    {
        path: `${AD}/*`,
        component: lazy(() => import("~/pages/AdvertisementPage")),
        isPrivate: true
    },
    {
        path: `${ANNOUNCEMENT}/:pageNo?/*`,
        component: lazy(() => import("~/pages/AnnouncementPage")),
        isPrivate: true
    },
    {
        path: `${ANN_DETAIL}/:annId?/*`,
        component: lazy(() => import("~/pages/AnnouncementDetailPage")),
        isPrivate: true
    },
    {
        path: `${REVIEW}/:pageNo?/*`,
        component: lazy(() => import("~/pages/ReviewPage")),
        isPrivate: true
    },
    {
        path: `${REPAIR_CASE}/:shopId/:pageNo?/*`,
        component: lazy(() => import("~/pages/RepairCasePage")),
        isPrivate: true
    },
    {
        path: `${REPAIR_CASE_WRITE}/:shopId/:caseType?/:caseId?/*`,
        component: lazy(() => import("~/pages/RepairCaseWritePage")),
        isPrivate: true
    },
    {
        path: `${REGIONAL_SETTINGS}/:shopId?/*`,
        component: lazy(() => import("~/pages/RegionalSettingsPage")),
        isPrivate: true
    },
    {
        path: `${MY_INFO}/:shopId?/*`,
        component: lazy(() => import("~/pages/MyInfoPage")),
        isPrivate: true
    }
];

export default routes;
