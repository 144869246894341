import {
    REQUEST_FETCH_CATEGORY_05,
    FAILURE_FETCH_CATEGORY_05,
    SUCCESS_FETCH_CATEGORY_05,
    REQUEST_FETCH_CATEGORY_06,
    FAILURE_FETCH_CATEGORY_06,
    SUCCESS_FETCH_CATEGORY_06,
    REQUEST_FETCH_CATEGORY_07,
    FAILURE_FETCH_CATEGORY_07,
    SUCCESS_FETCH_CATEGORY_07
} from "../actionTypes";

export function fetchCategory05() {
    return async (dispatch, getState, api) => {
        dispatch({
            type: REQUEST_FETCH_CATEGORY_05
        });

        try {
            const list = await api.fetchCategory05();

            dispatch({
                type: SUCCESS_FETCH_CATEGORY_05,
                payload: list
            });
        } catch (err) {
            dispatch({
                type: FAILURE_FETCH_CATEGORY_05,
                payload: err
            });
        }
    };
}

export function fetchCategory06() {
    return async (dispatch, getState, api) => {
        dispatch({
            type: REQUEST_FETCH_CATEGORY_06
        });

        try {
            const list = await api.fetchCategory06();

            dispatch({
                type: SUCCESS_FETCH_CATEGORY_06,
                payload: list
            });
        } catch (err) {
            dispatch({
                type: FAILURE_FETCH_CATEGORY_06,
                payload: err
            });
        }
    };
}

export function fetchCategory07() {
    return async (dispatch, getState, api) => {
        dispatch({
            type: REQUEST_FETCH_CATEGORY_07
        });

        try {
            const list = await api.fetchCategory07();

            dispatch({
                type: SUCCESS_FETCH_CATEGORY_07,
                payload: list
            });
        } catch (err) {
            dispatch({
                type: FAILURE_FETCH_CATEGORY_07,
                payload: err
            });
        }
    };
}
