export const Colors = {
    white: "#FFFFFF",
    black: "#000000",
    v5_gray50: "#F8F8F8",
    v5_gray100: "#F4F4F4",
    v5_gray200: "#F0F0F0",
    v5_gray300: "#E6E6E6",
    v5_gray400: "#E0E0E0",
    v5_gray500: "#C4C4C4",
    v5_gray600: "#9E9E9E",
    v5_gray700: "#5C5C5C",
    v5_gray800: "#424242",
    v5_gray900: "#222222",
    blue_gray_50: "#F7F7F8",
    blue_gray_100: "#F1F2F3",
    blue_gray_200: "#E4E5E7",
    blue_gray_300: "#C9CBCF",
    blue_gray_400: "#AEB2B7",
    blue_gray_500: "#93989F",
    blue_gray_600: "#787E87",
    blue_gray_700: "#60656C",
    blue_gray_800: "#484C51",
    blue_gray_900: "#303236",
    blue_100: "#0068FF",
    blue_90: "#E5F0FF",
    color_danger: "#EA3928",
    color_warning: "#F19C38",
    color_success: "#72C040",
    color_link: "#508DE9"
};
