import { toaster } from "evergreen-ui";

export function accessBocked() {
    toaster.danger("접근이 거부되었습니다. 관리자에게 문의하세요.");
}

export function sessionExpired() {
    toaster.warning("세션이 만료되었습니다. 로그인 화면으로 이동합니다.", {
        id: "session-expired"
    });
}

export function timeoutOccurred() {
    toaster.danger("서버가 응답하지 않습니다. 잠시 후 다시 시도하거나 관리자와 문의 해주세요.", {
        id: "timeout"
    });
}

export function noEstReqExistence(key) {
    toaster.danger(`필수 데이터가 응답하지 않습니다. ${key}`, {
        id: "no-existence"
    });
}

export function invalidAlert(msg) {
    toaster.danger(msg ?? "오류 입니다.");
}
