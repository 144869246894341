const {
    MODE,
    API_ENDPOINT,
    API_VERSION,
    API_PARTNERS_ENDPOINT,
    API_PARTNERS_VERSION,
    LEGACY_SHOPADMIN,
    AMPLITUDE_SDK_API,
    SENTRY,
    CHANNEL_IO_PLUGIN_KEY
} = process.env;

export const IS_DEV = MODE !== "production";
export {
    MODE,
    API_ENDPOINT,
    API_VERSION,
    API_PARTNERS_ENDPOINT,
    API_PARTNERS_VERSION,
    LEGACY_SHOPADMIN,
    AMPLITUDE_SDK_API,
    SENTRY,
    CHANNEL_IO_PLUGIN_KEY
};
