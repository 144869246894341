import styled from "@emotion/styled";
import { Button, Heading, Menu, MenuIcon, Popover, Position } from "evergreen-ui";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CardocLogoSvg from "~/assets/cardoc-logo.svg";
import FlexRow from "~/packages/ui/FlexRow";
import {
    CASH,
    CASH_CARD_REGISTRATION,
    CASH_HISTORY,
    CASH_RECHARGE,
    REGIONAL_SETTINGS,
    REPAIR_CASE,
    REPAIR_CASE_WRITE
} from "~/routes";

const MOBILE_HEADER_MENUS = [
    {
        title: "지역설정",
        path: REGIONAL_SETTINGS
    },
    {
        title: "수리사례",
        path: REPAIR_CASE
    },
    {
        title: "캐시관리",
        path: CASH
    }
];

function MobileHeader() {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [selectedTitle, setSelectedTitle] = useState();

    const { shopid: shopId } = useSelector(state => state.user);

    useEffect(() => {
        let title = "지역설정";
        const firstPath = `/${pathname.split("/")[1]}`;
        switch (firstPath) {
            case REGIONAL_SETTINGS:
                title = "지역설정";
                break;
            case REPAIR_CASE:
            case REPAIR_CASE_WRITE:
                title = "수리사례";
                break;
            case CASH:
            case CASH_HISTORY:
            case CASH_RECHARGE:
            case CASH_CARD_REGISTRATION:
                title = "캐시관리";
                break;
            default:
                break;
        }
        setSelectedTitle(title);
    }, [pathname]);

    const onSelectMenu = useCallback(
        (_path, title) => {
            setSelectedTitle(title);
            navigate(`${_path}/${shopId}`);
        },
        [navigate, shopId]
    );

    return (
        <Nav>
            <FlexRow alignItems={"end"}>
                <CardocLogoSvg />
                <Heading marginLeft={10}>{selectedTitle ?? ""}</Heading>
            </FlexRow>
            <Popover
                position={Position.TOP_RIGHT}
                content={({ close }) => (
                    <Menu>
                        <Menu.Group>
                            {MOBILE_HEADER_MENUS.map(menu => (
                                <Menu.Item
                                    onSelect={() => {
                                        onSelectMenu(menu.path, menu.title);
                                        close();
                                    }}
                                >
                                    {menu.title}
                                </Menu.Item>
                            ))}
                        </Menu.Group>
                    </Menu>
                )}
            >
                <Button>
                    <MenuIcon />
                </Button>
            </Popover>
        </Nav>
    );
}

export default MobileHeader;

const Nav = styled.nav`
    width: 100vw;
    background: #f5f6f7;
    padding: 16px;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    justify-content: space-between;
`;
