import App from "./App";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Amplitude, AmplitudeContext } from "~/packages/bootstrap";
import { AMPLITUDE_SDK_API } from "~/shared/env";
import { initiateSentry } from "~/shared/sentry";
import store from "~/store";

initiateSentry();

const amplitude = new Amplitude("shopadmin");
amplitude.init(AMPLITUDE_SDK_API);

ReactDOM.render(
    <Provider store={store}>
        <AmplitudeContext.Provider value={amplitude}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </AmplitudeContext.Provider>
    </Provider>,
    document.getElementById("app-root")
);
