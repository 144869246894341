import {
    FAILURE_FETCH_INSTANT_ADS_INFO,
    REQUEST_FETCH_INSTANT_ADS_INFO,
    SUCCESS_FETCH_INSTANT_ADS_INFO
} from "../actionTypes";

export function fetchInstantAdsInfo() {
    return async (dispatch, getState, api) => {
        const {
            user: { shopid: shopId }
        } = getState();
        dispatch({
            type: REQUEST_FETCH_INSTANT_ADS_INFO
        });

        try {
            const adsInfo = await api.fetchInstantAdsInfo(shopId);

            dispatch({
                type: SUCCESS_FETCH_INSTANT_ADS_INFO,
                payload: adsInfo
            });
        } catch (err) {
            dispatch({
                type: FAILURE_FETCH_INSTANT_ADS_INFO,
                payload: err
            });
        }
    };
}
