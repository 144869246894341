/**
 * It was originally '신규업체' but changed to '무과금 업체'
 * No charge until special circumstance
 * @param  {Object}  shop
 * @return {Boolean}
 */
function detectNoChargeShop(shop) {
    return shop.incubatingyn === "Y";
}

export default detectNoChargeShop;
