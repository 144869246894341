import {
    REQUEST_FETCH_TERMS,
    FAILURE_FETCH_TERMS,
    SUCCESS_FETCH_TERMS,
    REQUEST_FETCH_REPAIR_SHOPS,
    FAILURE_FETCH_REPAIR_SHOPS,
    SUCCESS_FETCH_REPAIR_SHOPS
} from "../actionTypes";

const initialState = {
    terms: {
        isLoading: false,
        isLoaded: false,
        list: [],
        error: null
    },
    shops: {
        isLoading: false,
        isLoaded: false,
        list: [],
        error: null
    }
};

function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case REQUEST_FETCH_TERMS: {
            return {
                ...state,
                terms: {
                    isLoading: true,
                    isLoaded: false,
                    list: [],
                    error: null
                }
            };
        }

        case FAILURE_FETCH_TERMS: {
            return {
                ...state,
                terms: {
                    isLoading: false,
                    isLoaded: true,
                    list: [],
                    error: payload
                }
            };
        }

        case SUCCESS_FETCH_TERMS: {
            return {
                ...state,
                terms: {
                    isLoading: false,
                    isLoaded: true,
                    list: payload,
                    error: null
                }
            };
        }

        case REQUEST_FETCH_REPAIR_SHOPS: {
            return {
                ...state,
                shops: {
                    isLoading: true,
                    isLoaded: false,
                    list: [],
                    error: null
                }
            };
        }

        case FAILURE_FETCH_REPAIR_SHOPS: {
            return {
                ...state,
                shops: {
                    isLoading: false,
                    isLoaded: true,
                    list: [],
                    error: payload
                }
            };
        }

        case SUCCESS_FETCH_REPAIR_SHOPS: {
            return {
                ...state,
                shops: {
                    isLoading: false,
                    isLoaded: true,
                    list: payload,
                    error: null
                }
            };
        }

        default: {
            return state;
        }
    }
}

export default reducer;
