/**
 * Reload entire details page without page refresh
 * NOTE to avoid dependency cycle
 * @param  {Function} dispatch
 * @param  {String}   estId
 * @return {Promise}
 */
export function reloadDetails(dispatch, estId) {
    return import("./actions/details").then(({ fetchEstimation }) => {
        dispatch(fetchEstimation(estId));
    });
}
