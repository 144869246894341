import { isNil } from "ramda";

// expected)
// 1
// "1000"
// 1,000
// "1,000,000"

// unexpected)
// "1o00"
// "1백만원"
// "일천만오백원"
// 콤마를 포함한 숫자가 아닌 경우는 무조건 터트림
export function makeSafeNumber(price) {
    try {
        if (typeof price === "string") {
            const result = Number(price.replace(/,/g, ""));
            if (price.length === 0) {
                return price;
            }
            if (!isNaN(result)) {
                return result;
            } else {
                throw new Error("makeSafeNumber: invalid input");
            }
        }

        if (price === null || price === undefined || isNaN(price)) {
            throw new Error("makeSafeNumber: invalid input");
        }

        return parseInt(price, 10);
    } catch (_e) {
        throw new Error("makeSafeNumber: invalid input");
    }
}

function createApplyCurrency({ prependUnit = false, locale = "ko-KR", currency = "KRW" } = {}) {
    return currencyNumber => {
        if (currencyNumber === "" || isNil(currencyNumber)) {
            return currencyNumber;
        }

        let parsedCurrency = currencyNumber;

        if (typeof parsedCurrency !== "number") {
            parsedCurrency = parseInt(parsedCurrency, 10);
        }

        if (Number.isNaN(parsedCurrency)) {
            parsedCurrency = 0;
        }

        const origCurrency = parsedCurrency.toLocaleString(locale, {
            style: "currency",
            currency
        });

        return prependUnit ? origCurrency : origCurrency.substr(1);
    };
}

const applyCurrency = createApplyCurrency();
applyCurrency.withOptions = createApplyCurrency;

export default applyCurrency;
