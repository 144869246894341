import { genericError } from "~/packages/utils";
import { FAILURE_GET_MY_INFO, REQUEST_GET_MY_INFO, SUCCESS_GET_MY_INFO } from "../actionTypes";

export function fetchMyInfo() {
    return async (dispatch, getState, api) => {
        const {
            user: { shopid: shopId }
        } = getState();
        try {
            if (!shopId) {
                throw genericError("no shopId");
            }

            dispatch({
                type: REQUEST_GET_MY_INFO
            });

            if (shopId) {
                const myInfo = await api.getMyShop(shopId);

                dispatch({
                    type: SUCCESS_GET_MY_INFO,
                    payload: myInfo
                });
            }
        } catch (err) {
            dispatch({
                type: FAILURE_GET_MY_INFO,
                payload: err
            });
        }
    };
}
