import {
    FAILURE_GET_CARDOC_CASH,
    FAILURE_GET_UNREAD_NOTI_COUNT,
    REQUEST_GET_CARDOC_CASH,
    REQUEST_GET_UNREAD_NOTI_COUNT,
    SUCCESS_GET_CARDOC_CASH,
    SUCCESS_GET_UNREAD_NOTI_COUNT
} from "../actionTypes";

export function getUnreadNotiCount() {
    return async (dispatch, getState, api) => {
        const {
            user: { shopid: shopId }
        } = getState();

        try {
            dispatch({
                type: REQUEST_GET_UNREAD_NOTI_COUNT
            });

            const unreadNotiCount = await api.getUnreadNotiCount(shopId);

            dispatch({
                type: SUCCESS_GET_UNREAD_NOTI_COUNT,
                payload: unreadNotiCount
            });
        } catch (err) {
            dispatch({
                type: FAILURE_GET_UNREAD_NOTI_COUNT,
                payload: err
            });
        }
    };
}

export function getBalance(shopId) {
    return async (dispatch, getState, api) => {
        try {
            dispatch({
                type: REQUEST_GET_CARDOC_CASH
            });

            const balance = await api.getBalance(shopId);

            dispatch({
                type: SUCCESS_GET_CARDOC_CASH,
                payload: balance
            });
        } catch (err) {
            dispatch({
                type: FAILURE_GET_CARDOC_CASH,
                payload: err
            });
        }
    };
}
