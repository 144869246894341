import {
    REQUEST_SUBMIT_CONFIRMATION,
    SUCCESS_SUBMIT_CONFIRMATION,
    FAILURE_SUBMIT_CONFIRMATION,
    REQUEST_CONFIRM_PAID,
    SUCCESS_CONFIRM_PAID,
    FAILURE_CONFIRM_PAID,
    UPDATE_CONFIRMATION_FIELD
} from "../actionTypes";
import { reloadDetails } from "../helpers";

export function updateConfirmationField({ name, value }) {
    return {
        type: UPDATE_CONFIRMATION_FIELD,
        payload: {
            name,
            value
        }
    };
}

export function submitConfirmation() {
    return async (dispatch, getState, api) => {
        const {
            details: { estId },
            formConfirmation: { form }
        } = getState();

        dispatch({
            type: REQUEST_SUBMIT_CONFIRMATION
        });

        try {
            await api.updateEstimate({
                data: form,
                estId
            });

            dispatch({
                type: SUCCESS_SUBMIT_CONFIRMATION
            });

            reloadDetails(dispatch, estId);
        } catch (err) {
            dispatch({
                type: FAILURE_SUBMIT_CONFIRMATION,
                payload: err
            });
        }
    };
}

export function confirmPaid() {
    return async (dispatch, getState, api) => {
        const {
            details: { estId }
        } = getState();

        dispatch({
            type: REQUEST_CONFIRM_PAID
        });

        try {
            await api.updateEstimate({
                data: { updatetype: "confirm_payatshop" },
                estId
            });

            dispatch({
                type: SUCCESS_CONFIRM_PAID
            });

            reloadDetails(dispatch, estId);
        } catch (err) {
            dispatch({
                type: FAILURE_CONFIRM_PAID,
                payload: err
            });
        }
    };
}
