import { makeSafeNumber } from "../../packages/utils/applyCurrency.js";
/**
 * Create estimate structure (API)
 * - Saving data by `statuscode`
 * @param  {String} estId
 * @param  {Object} estimation
 * @param  {Array}  price
 * @return {Object}
 */
function estimate({ estId, estimation, price, estReq }) {
    const { ad_est_words: adEstWords, hashedid: hashEdid, reqid: reqId, statuscode, desc, period } = estimation;

    const statusCode = Number(statuscode);

    // type - cash, insurance
    const [insuranceItem] = price.filter(({ type }) => type === "insurance");
    const { deductible = 0 } = insuranceItem || {};

    // group by type
    const { cash, insurance } = price.reduce((acc, { type, item: items }) => {
        return {
            ...acc,
            [type]: items
        };
    }, {});

    let adBrandId = estimation.ad_brandid;

    if (statusCode === 100) {
        const { brandId } = estimation.availableBrandAd || {};

        adBrandId = adBrandId || brandId;
    }

    return {
        hashedid: hashEdid,
        estid: estId,
        reqid: reqId,
        ad_est_words: adEstWords.map(({ ad_id: id }) => id).join("|"),
        ad_brandid: adBrandId,
        statuscode: statusCode,
        period_unit: "일",
        deductibleItem: {
            price: makeSafeNumber(
                statusCode === 500 && estReq.insurance === "victim" ? 0 : deductible.toString().replace(/[^0-9]/g, "")
            ),
            estimationtype: "01",
            estimationtype_code_category: "01",
            price_component_type: "02",
            price_component_type_category: "02"
        },
        priceItems: {
            cash,
            insurance
        },
        desc,
        period
    };
}

export default estimate;
