import { combineReducers } from "redux";
import ads from "./ads";
import advertisement from "./advertisement";
import announcement from "./announcement";
import assets from "./assets";
import chat from "./chat";
import details from "./details";
import estimations from "./estimations";
import formBooking from "./form-booking";
import formCategory from "./form-category";
import formConfirmation from "./form-confirmation";
import formEstimate from "./form-estimate";
import formFilters from "./form-filters";
import formInvoice from "./form-invoice";
import formLogin from "./form-login";
import initialization from "./initialization";
import myInfo from "./my-info";
import notification from "./notification";
import repairCase from "./repair-case";
import review from "./review";
import shopAssignInfo from "./shop-assign-info";
import user from "./user";
import cash from "./cash";
import cashHistory from "./cash-history";

export default combineReducers({
    announcement,
    assets,
    user,
    estimations,
    details,
    ads,
    chat,
    formLogin,
    formEstimate,
    formCategory,
    formBooking,
    formInvoice,
    formConfirmation,
    formFilters,
    notification,
    initialization,
    review,
    myInfo,
    advertisement,
    repairCase,
    shopAssignInfo,
    cash,
    cashHistory
});
