import { compose } from "ramda";

import getDateString from "./getDateString";
import resolveDateString from "./resolveDateString";

function getLocalizedDateString(dateString) {
    if (!dateString) {
        return dateString;
    }

    return compose(getDateString, resolveDateString)(dateString);
}

export default getLocalizedDateString;
