/**
 * The shortest location that filtered by specific conditions
 * @param  {Array}  [locations=[]]
 * @return {Object}
 */
function getPreferredLocation(locations = []) {
    const [preferredLocation] = locations
        .filter(({ suggest_yn: yN }) => yN === "N")
        .sort((a, b) => {
            return Number(a.distance) > Number(b.distance) ? 1 : -1;
        });

    return preferredLocation;
}

export default getPreferredLocation;
