import { FAILURE_FETCH_ADVERTISEMENT, REQUEST_FETCH_ADVERTISEMENT, SUCCESS_FETCH_ADVERTISEMENT } from "../actionTypes";

const initialState = {
    isLoading: false,
    isLoaded: false,
    localAdvertisementList: [],
    error: null
};

function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case REQUEST_FETCH_ADVERTISEMENT: {
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                error: null
            };
        }

        case FAILURE_FETCH_ADVERTISEMENT: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                localAdvertisementList: [],
                error: payload
            };
        }

        case SUCCESS_FETCH_ADVERTISEMENT: {
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                localAdvertisementList: payload,
                error: null
            };
        }

        default: {
            return state;
        }
    }
}

export default reducer;
