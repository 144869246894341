import { RESET_FILTERS, UPDATE_FILTER } from "../actionTypes";
import { isFunction } from "lodash";

export function updateFilter({ name, value }, handleAfterUpdate) {
    return dispatch => {
        dispatch({
            type: UPDATE_FILTER,
            payload: {
                name,
                value
            }
        });

        if (handleAfterUpdate && isFunction(handleAfterUpdate)) {
            handleAfterUpdate();
        }
    };
}

export function resetFilters(handleAfterReset) {
    return dispatch => {
        dispatch({
            type: RESET_FILTERS
        });

        if (handleAfterReset && isFunction(handleAfterReset)) {
            handleAfterReset();
        }
    };
}
