import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

function ProtectRoute({ isPrivate, children }) {
    const [isPending, setIsPending] = useState(true);
    const { isLoading, type } = useSelector(state => state.user);

    const isLoggedIn = !!type;
    const isProtect = isPrivate && !isLoggedIn;

    useEffect(() => {
        if (!isLoading) {
            // verifyAuth 함수 호출로 인증 확인 후 화면을 보여주기 위함
            setIsPending(false);
        }
    }, [isLoading]);

    if (isProtect && !isPending) {
        return <Navigate to={"/login"} replace />;
    }

    return children;
}

export default ProtectRoute;

ProtectRoute.propTypes = {
    isPrivate: PropTypes.bool.isRequired
};
