import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { identity } from "ramda";
import { composeWithDevTools } from "redux-devtools-extension";
import * as api from "~/services/api";
import { IS_DEV } from "~/shared/env";
import reducers from "./reducers";

const composeDev = IS_DEV ? composeWithDevTools : identity;

function configureStore(preloadedState) {
    const middlewares = [thunk.withExtraArgument(api)];
    const middlewareEnhancer = applyMiddleware(...middlewares);
    const composedEnhancer = composeDev(middlewareEnhancer);
    const store = createStore(reducers, preloadedState, composedEnhancer);

    return store;
}

export default configureStore;
