import React, { memo } from "react";
import PropTypes from "prop-types";
import Flex from "./Flex";

function FlexMiddle({ children, ...props }) {
    return (
        <Flex alignItems={"center"} justifyContent={"center"} {...props}>
            {children}
        </Flex>
    );
}

FlexMiddle.propTypes = {
    children: PropTypes.node.isRequired
};

export default memo(FlexMiddle);
