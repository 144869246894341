import React, { memo } from "react";
import PropTypes from "prop-types";
import Flex from "./Flex";

function FlexRow({ children, ...props }) {
    return (
        <Flex flexDirection={"row"} {...props}>
            {children}
        </Flex>
    );
}

FlexRow.propTypes = {
    children: PropTypes.node.isRequired
};

export default memo(FlexRow);
