import { PAYMENT_MAP } from "~/presets";
import {
    FAILURE_SUBMIT_INVOICE,
    INIT_INVOICE,
    REQUEST_SUBMIT_INVOICE,
    SUCCESS_SUBMIT_INVOICE,
    UPDATE_INVOICE_FIELD
} from "../actionTypes";
import { reloadDetails } from "../helpers";
import { nowDateTime } from "../../packages/utils";

export function initInvoice(invoiceFields) {
    return {
        type: INIT_INVOICE,
        payload: invoiceFields
    };
}

export function updateInvoiceField({ name, value }) {
    return {
        type: UPDATE_INVOICE_FIELD,
        payload: {
            name,
            value
        }
    };
}

export function submitInvoice(sendType) {
    return async (dispatch, getState, api) => {
        const {
            details: { estId },
            formInvoice: { form }
        } = getState();

        const updatedForm = {
            ...form,
            statement: {
                ...form.statement,
                estimationtype: PAYMENT_MAP[sendType],
                release_date: form.statement.release_date || nowDateTime("yyyy-MM-dd HH:mm:ss")
            }
        };

        dispatch({
            type: REQUEST_SUBMIT_INVOICE
        });

        try {
            await api.updateEstimate({
                data: updatedForm,
                estId
            });

            dispatch({
                type: SUCCESS_SUBMIT_INVOICE
            });

            reloadDetails(dispatch, estId);
        } catch (err) {
            dispatch({
                type: FAILURE_SUBMIT_INVOICE,
                payload: err
            });
        }
    };
}
